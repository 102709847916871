import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  getAllProductsAdmin,
  getAllProductsWithPage,
  updateProduct,
  updateProductStatus,
} from "../../redux/actions/productsAction";
import { getSettings } from "../../redux/actions/settingsAcions";
import notify from "../useNotification";

const ViewProductAdminHook = () => {
  const [loading, setLoading] = useState(true);
  const [showSelectStoreComponent, setShowSelectStoreComponent] =
    useState(false);
  const [showAddComponnet, setShowAddComponent] = useState(true);
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));
  console.log('user');
      console.log(user);

  const storeIdRes = useSelector(
    (state) => state.allStores.selectStoreBySuperAdmin
  );

  useEffect(() => {
    // if (localStorage.getItem("page")) {
    //   // console.log('page')
    //   // console.log(localStorage.getItem('page'))
    //   getPage(localStorage.getItem("page"));
    // } else {
      

      if (user.role == "superAdmin") {
        console.log('sssssssssssssssssssssssssssss');
        setShowSelectStoreComponent(true);
        if (storeIdRes !== "") {
          console.log("ssss");
          dispatch(getAllProductsAdmin(9, storeIdRes,'admin'));
          setShowAddComponent(true);
        } else {
          setShowAddComponent(false);
        }
      } else {
        setShowAddComponent(true);
        setShowSelectStoreComponent(false);
        dispatch(getAllProductsAdmin(9, user?.storeId,'admin'));
      }
    // }
  }, [storeIdRes, user.role, user?.storeId]);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const allproduct = useSelector((state) => state.allProduct.allProductsAdmin);
  const getPage = async (page) => {
    localStorage.setItem("page", page);
    if (user.role === "superAdmin") {
      await dispatch(getAllProductsWithPage(page, 9, storeIdRes,'admin'));
    } else {
      await dispatch(getAllProductsWithPage(page, 9, user?.storeId,'admin'));
    }
  };
  let items = [];
  if (allproduct)
    if (allproduct.data) items = allproduct.data;
    else items = [];
  let pagination = [];
  if (allproduct)
    if (allproduct.numberOfPages) pagination = allproduct.numberOfPages;
    else pagination = [];

  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateProductStatus(id, {
        status: status,
      })
    );
    setLoading(false);
  };
  const ress = useSelector((state) => state.allProduct.updateProductStatus);

  useEffect(() => {
    if (loading === false) {
      if (ress) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          //localStorage.setItem("page", page);
          if (user.role === "superAdmin") {
             dispatch(getAllProductsWithPage(1,9, storeIdRes,'admin'));
          } else {
             dispatch(getAllProductsWithPage( 1,9, user?.storeId,'admin'));
          }
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [
    items,
    pagination,
    getPage,
    handleShowFun,
    settingsRes,
    showSelectStoreComponent,
    showAddComponnet,
    storeIdRes,
  ];
};

export default ViewProductAdminHook;
