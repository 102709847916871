import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";
import { createOrders } from "../../redux/actions/checkoutAction";
import UserCartHook from "../cart/UserCartHook";
import getStoreNameFromUrl from "../../Components/Utilities/getStoreNameFromUrl";

const storeName=getStoreNameFromUrl();

const PaymentStatusHook = () => {
  const dispatch = useDispatch();
  let url_string = window.location.href;
  let url = new URL(url_string);
  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }
  const responseObject = {
    PaymentId: url.searchParams.get("PaymentId"),
    Result: url.searchParams.get("Result"),
    ResponseCode: url.searchParams.get("ResponseCode"),
    UserField1: url.searchParams.get("UserField1").split(","),
    Result: url.searchParams.get("Result"),
  };
  const [
    itemNumber,
    cartItems,
    totalPrice,
    couponNameAfterApplay,
    totalPriceAfterDiscount,
  ] = UserCartHook();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const totalPriceData =
    totalPriceAfterDiscount > 0 ? totalPriceAfterDiscount : totalPrice;

  useEffect(() => {
    const get = async () => {
      if (
        responseObject.ResponseCode == "000" &&
        responseObject.Result == "Successful" &&
        cartItems.length != 0
      ) {
        setLoading(true);
        await dispatch(
          createOrders({
            orderItems: cartItems.map((el) => {
              return {
                quantity: el.quantity,
                classification: el.classification,
                product: el.product._id,
                price: el.product.price1,
                color: el.color,
              };
            }),
            vendor: cartItems[0]?.product.vendor,
            user: user._id,
            userAddress: responseObject.UserField1[1],
            paymentMethodId: responseObject.UserField1[0],
            totalPrice:
              parseInt(totalPriceData) + parseInt(responseObject.UserField1[2]),
            isPaid: true,
            storeId: user?.storeId,
          })
        );
        setLoading(false);
      }
    };
    get();
  }, [cartItems]);

  const res = useSelector((state) => state.checkoutReducer.createOrder);
  useEffect(() => {
    if (loading === false) {
      if (res && res.success === true) {
        notify("تم انشاء الطلب بنجاح", "success");
        setTimeout(() => {
          navigate(`/${storeName}/user/allorders`);
        }, 2500);
      } else {
        notify("فشل فيه اكمال الطلب", "warn");
      }
    }
  }, [loading]);

  return [responseObject];
};
export default PaymentStatusHook;
