import React, { useEffect, useState } from "react";
import {
  addCoupon,
  deleteCoupon,
  getAllCoupon,
} from "../../redux/actions/couponAction";
import { getSettings } from "../../redux/actions/settingsAcions";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AddCouponHook = () => {
  const [couponName, setCouponName] = useState("");
  const [couponDate, setCouponDate] = useState("");
  const [couponValue, setCouponValue] = useState("");
  const [couponType, setCouponType] = useState("");

  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [coponId, setcoponId] = useState("");

  const [showSelectStoreComponent, setShowSelectStoreComponent] =
    useState(false);
  const [showAddComponnet, setShowAddComponent] = useState(true);

  const [selectedStore, setSelectedStore] = useState("");

  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setcoponId(id);
    setShow(true);
  };

  const handleDelete = async () => {
    await dispatch(deleteCoupon(coponId));
    setShow(false);
    notify(t("Delete successfully"), "success");
    setTimeout(() => {
      window.location.reload(true);
    }, 1000);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChangeCouponName = (e) => {
    e.persist();
    setCouponName(e.target.value);
  };
  const onChangeCouponDate = (e) => {
    e.persist();
    setCouponDate(e.target.value);
  };
  const onChangeCouponValue = (e) => {
    e.persist();
    setCouponValue(e.target.value);
  };
  const onChangeCouponType = (e) => {
    e.persist();
    setCouponType(e.target.value);
  };

  //on change store
  const changeStore = (e) => {
    console.log("e.target.value");
    console.log(e.target.value);
    setSelectedStore(e.target.value);
  };

  //all store response
  const allStoresResponse = useSelector((state) => state.allStores.allStores);

  //get response store
  const storeIdRes = useSelector(
    (state) => state.allStores.selectStoreBySuperAdmin
  );

  const onSubmit = async () => {
    if (
      couponName === "" ||
      !couponType ||
      couponValue <= 0 ||
      couponDate === ""
    ) {
      notify("من فضلك اكمل البيانات", "error");
    }
    if (user?.role === "superAdmin" && selectedStore === "") {
      notify("من فضلك تأكد من اختيار المتجر", "error");
      return;
    }

    setLoading(true);
    await dispatch(
      addCoupon({
        title: couponName.toLowerCase(),
        endDate: couponDate,
        type: couponType,
        value: couponValue,
        storeId: user?.role === "superAdmin" ? selectedStore : user?.storeId,
      })
    );
    setLoading(false);
  };

  const clickHandler = (id) => {
    navigate(`/admin/editcoupon/${id}`);
  };

  const res = useSelector((state) => state.couponReducer.addCoupon);

  useEffect(() => {
    if (loading === false) {
      if (res && res.success === true) {
        setCouponName("");
        setCouponDate("");
        setCouponValue("");
        setCouponType("");
        notify("تم الحفظ بنجاح", "success");
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      } else if (
        res &&
        res.data.message === "Coupon already found in this name"
      ) {
        notify("هذا الكوبون مضاف من قبل", "error");
      } else {
        notify("فشل في عملية الاضافه", "error");
      }
    }
  }, [loading]);

  const allCoupon = useSelector((state) => state.couponReducer.allCoupon);

  useEffect(() => {
    const get = async () => {
      if (user.role === "superAdmin") {
        setShowSelectStoreComponent(true);
        if (storeIdRes !== "") {
          await dispatch(getAllCoupon(storeIdRes));
          setShowAddComponent(true);
        } else {
          setShowAddComponent(false);
        }
      } else dispatch(getAllCoupon(user?.storeId));
    };
    get();
  }, [dispatch, storeIdRes, user.role, user?.storeId]);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  let coupons = [];
  try {
    if (allCoupon && allCoupon.data.length >= 1) coupons = allCoupon.data;
  } catch (e) {}

  return [
    couponName,
    couponDate,
    couponValue,
    couponType,
    onChangeCouponName,
    onChangeCouponDate,
    onChangeCouponValue,
    onChangeCouponType,
    onSubmit,
    coupons,
    clickHandler,
    show,
    handleShow,
    handleClose,
    handleDelete,
    settingsRes,
    showSelectStoreComponent,
    showAddComponnet,
    storeIdRes,
    changeStore,
    user,
    allStoresResponse,
  ];
};

export default AddCouponHook;
