import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const VideosLearning = () => {
  const { t } = useTranslation();
  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }
  return (
    <div>
      {user?.role === "admin" || user.role === "superAdmin" ? (
        <Fragment>
          <h3 className="m-3">1-{t("Admin instructional video")}</h3>
          <iframe
            width="560"
            height="315"
            className="m-3"
            src="https://www.youtube.com/embed/zneUWnWZAcE"
            title={t("Admin instructional video")}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="1"
          ></iframe>
        </Fragment>
      ) : null}

      <h3 className="m-3">
        {user?.role === "admin" || user?.role === "superAdmin" ? "2-" : "1-"}
        {t("Vendor Instructional Video")}
      </h3>
      <iframe
        width="560"
        height="315"
        className="m-3"
        frameborder="1"
        src="https://www.youtube.com/embed/Of9DlwtnaJs"
        title={t("Vendor Instructional Video")}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideosLearning;
