import React from "react";
import ViewProductVendorHook from "../../hook/vendor/ViewProductVendorHook";
import DataTable from "react-data-table-component";
import add from "../../Images/add.png";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button, Row } from "react-bootstrap";
import AdminProductCard from "../Admin/AdminProductCard";

import AddButton from "../Utilities/AddButton";
import { useSelector } from "react-redux";
import getStoreNameFromUrl from "../Utilities/getStoreNameFromUrl";

const storeName=getStoreNameFromUrl();

const VendorAllProducts = () => {
  const [productsByVendorId] = ViewProductVendorHook();

  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const [card, setCard] = useState(false);
  const navigate = useNavigate();
  const handleTable = () => {
    setCard(!card);
  };
  const columns = [
    {
      name: "الصوره",
      cell: (row) => (
        <img
          src={row.image}
          width="50px"
          alt="imge of product"
          height="50px"
          style={{ padding: "3px", objectFit: "contain" }}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "اسم المنتج",
      selector: "nameAr",
      sortable: true,
    },
    {
      name: "الماركة",
      selector: (row) => row?.brand?.nameAr,
      sortable: true,
    },
    {
      name: "التصنيف",
      selector: (row) => row?.category?.nameAr,
      sortable: true,
    },
    {
      name: "التصنيف الفرعي",
      selector: (row) => row?.subCategory?.nameAr,
      sortable: true,
    },

    {
      name: "السعر",
      selector: "price1",
      sortable: true,
    },
    {
      name: "التقييم",
      selector: "ratingsAverage",
      sortable: true,
    },
    {
      name: "عدد المقيمين",
      selector: "ratingsQuantity",
      sortable: true,
    },
    {
      name: "التعديل",
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => navigate(`/vendor/editProduct/${row._id}`)}
          id={row._id}
        >
          تعديل
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: productsByVendorId,
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="sub-tile" style={{ color: settingsRes?.data?.primaryColor }}>
          <div className="admin-content-text">ادارة جميع المنتجات</div>
        </div>
        <AddButton title='إضافة منتج' navigateTo={`/vendor/addproduct`} />




      </div>
      {card === true ? (
        <Row className="justify-content-center">
          {productsByVendorId ? (
            productsByVendorId.map((item, index) => (
              <AdminProductCard key={index} item={item} />
            ))
          ) : (
            <h3>لا يوجد منتجات</h3>
          )}
        </Row>
      ) : (
        <DataTableExtensions {...tableData} filterPlaceholder="ابحث هنا">
          <DataTable
            noHeader
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      )}
    </div>
  );
};

export default VendorAllProducts;

// <div>
// <Button
// style={{backgroundColor:'darkgreen'}}
// variant="secondary"
// onClick={() => navigate(`/vendor/addproduct`)}

// >
// إضافة منتج
// <img
// src={add}
// alt=""
// width="30px"
// height="35px"
// style={{ cursor: "pointer", marginRight:'8px'}}
// onClick={()=>navigate(`/vendor/addproduct`)}
// />
// </Button>
// </div>