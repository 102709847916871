import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import getStoreNameFromUrl from '../../Components/Utilities/getStoreNameFromUrl';
import { useDispatch, useSelector } from 'react-redux';
import { getLastSubscription } from '../../redux/actions/subscriptionAction';
import SubscriptionEndComponent from '../../Components/Utilities/SubscriptionEndComponent';
import LoginSuperAdmin from '../../Pages/Auth/LoginSuperAdmin';



const storeName = getStoreNameFromUrl();
function CheckIfStoreIsActiveHook() {

  const dispatch=useDispatch();
  const storeNameResponse = useSelector((state) => state.allStores.oneStore);

 async function getStore(){
  if(storeNameResponse?._id) await dispatch(getLastSubscription(storeNameResponse?._id));
  }
  useEffect(()=>{
    getStore();
  },[storeNameResponse]);

  const lastSubscribtionObj=useSelector((state)=>state.subscriptionReducer.lastSubscription);
  const loading=useSelector((state)=>state.subscriptionReducer.loading);
  let renderApp=false;

 

  console.log('lastSubscribtionObj');
  console.log(lastSubscribtionObj);


   
    // console.log('storeName..............');
    // console.log(!storeName);

    console.log('lastSubscribtionObj._id');
    console.log(lastSubscribtionObj);
    console.log(Date.now())

    // console.log('loading');
    // console.log(loading);
    
    if(loading) return <h1>loading......</h1>;

    //storeadminResgiter
    //case we open / open login as super admin page
    //(lastSubscribtionObj?._id && lastSubscribtionObj.endDateSubscription > Date.now())
    if(storeName==''||lastSubscribtionObj?._id) renderApp=true;

    else renderApp=false;

    
    // if(!storeName) return <LoginSuperAdmin />;
    //if(!storeName) console.log('empttttttty');
    
  return  renderApp? <Outlet /> : <SubscriptionEndComponent />
  
}

export default CheckIfStoreIsActiveHook
