import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ViewAllUsersHook from "../../hook/admin/ViewAllUsersHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import AdminEditUserHook from "../../hook/admin/AdminEditUserHook";
import { updateUser } from "../../redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { ToastContainer } from "react-toastify";
import add from "../../Images/add.png";
import { useNavigate } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import getStoreNameFromUrl from "../Utilities/getStoreNameFromUrl";
import SuperAdminSelectStoreComponent from "../Utilities/SuperAdminSelectStoreComponent";

const storeName = getStoreNameFromUrl();

const AdminAllUsers = () => {
  const { t } = useTranslation();
  const [
    users,
    clickHandler,
    webSiteData,
    handleShowFun,
    showReviews,
    settings,
    showSelectStoreComponent,
    showAddComponnet,
    showModal,setShowModal,
    showModalHandler,
    newPassword,setNewPassword,
    changePassword
  ] = ViewAllUsersHook();

  const navigate = useNavigate();

  console.log(showAddComponnet);
  console.log(showSelectStoreComponent);

  useEffect(() => {
    if (webSiteData.MULTI_VENDOR === "true") {
      columns.unshift({
        name: t("user type"),
        selector: "role",
        sortable: true,
      });
    }
  }, [users]);

  let columns = [
    {
      name: t("first name"),
      selector: "firstname",
      sortable: true,
    },
    {
      name: t("last name"),
      selector: "lastname",
      sortable: true,
    },
    {
      name: t("phone"),
      selector: "mobile",
      sortable: true,
    },
    {
      name:'تغيير كلمة المرور',
      cell:(row)=>(
        <button onClick={()=>showModalHandler(row)} className="btn btn-primary">تغيير كلمة السر</button>
      )
    },
    {
      name: t("Wallet"),
      cell: (row) => (
        <view
          style={{ cursor: "pointer" }}
          variant="secondary"
          onClick={() => navigate(`/admin/userTransactions/${row._id}`)}
          id={row._id}
        >
          {t("press here")}
          <i className="fa fa-folder-open-o mx-1" />
        </view>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
    {
      name: t("email"),
      selector: "email",
      sortable: true,
    },
    {
      name: t("Modify"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("the status"),
      cell: (row) =>
        row.status == true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
            {t("active")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: users,
  };

  return (
    <div>
      <Helmet>
        <title>
          {` ${t("users")} -  ${
            Cookies.get("i18next") === "ar"
              ? settings.data?.titleAr
              : settings.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>

      <Modal show={showModal} onHide={()=>setShowModal(false)} >
        <Modal.Header>
          <Modal.Title>
            تغيير كلمة المرور
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <label htmlFor="newPassword">كلمة السر الجديدة</label>
         <input id="newPassword" className="form-control" value={newPassword} onChange={(e)=>setNewPassword(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="font"
            variant="secondary"
             onClick={()=>setShowModal(false)}
          >
            {t("retreat")}
          </Button>
          <Button onClick={()=>changePassword()} className="font" variant="success" >
            حفظ
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex justify-content-between">
        <div className="admin-content-text">{t("User Management")} </div>

        {showAddComponnet && (
          <AddButton title={t("add user")} navigateTo={`/admin/addUser`} />
        )}
      </div>

      {showSelectStoreComponent === true && <SuperAdminSelectStoreComponent />}

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};
export default AdminAllUsers;
