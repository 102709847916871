import React, { useState } from "react";
import { Button, Row } from "react-bootstrap";
import AdminProductCard from "./AdminProductCard";
import DataTable from "react-data-table-component";
//  import { t } from "i18next";
import Cookies from "js-cookie";
import DataTableExtensions from "react-data-table-component-extensions";
import { useNavigate } from "react-router-dom";

import add from "../../Images/add.png";
import ViewProductAdminHook from "../../hook/admin/ViewProductAdminHook";
import { ToastContainer } from "react-toastify";
import AddButton from "../Utilities/AddButton";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import getStoreNameFromUrl from "../Utilities/getStoreNameFromUrl";
import SuperAdminSelectStoreComponent from "../Utilities/SuperAdminSelectStoreComponent";

const storeName = getStoreNameFromUrl();

const AdminProducts = ({ products }) => {
  const { t } = useTranslation();

  const [
    items,
    pagination,
    getPage,
    handleShowFun,
    settingsRes,
    showSelectStoreComponent,
    showAddComponnet,
    storeIdRes,
  ] = ViewProductAdminHook();

  const [card, setCard] = useState(false);
  const navigate = useNavigate();

  // console.log('showSelectStoreComponent');
  // console.log(showSelectStoreComponent);

  const columns = [
    {
      name: t("image"),
      cell: (row) => (
        <img
          src={row.image}
          width="50px"
          alt="productImage"
          height="50px"
          style={{
            padding: "2px",
            justifyContent: "center",
            alignItems: "center",
            objectFit: "contain",
          }}
          loading="lazy"
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("product name"),

      selector: (row) =>
        Cookies.get("i18next") === "ar" ? row["nameAr"] : row["nameEn"],
      cell: (row) => (
        <div
          title={
            Cookies.get("i18next") === "ar" ? row["nameAr"] : row["nameEn"]
          }
          style={{
            width: "85%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            height: "15px",
          }}
        >
          {Cookies.get("i18next") === "ar" ? row["nameAr"] : row["nameEn"]}
        </div>
      ),
      sortable: true,
    },
    // {
    //   name: t("brand"),
    //   //Cookies.get("i18next")=="ar"?row.brand.nameAr:row.brand.nameEn
    //   selector: (row) => Cookies.get("i18next") === "ar" ? row.brand?.nameAr : row.brand.nameEn,
    //   sortable: true,
    // },
    {
      name: t("category"),

      selector: (row) =>
        Cookies.get("i18next") === "ar"
          ? row.category?.nameAr
          : row.category.nameEn,
      sortable: true,
    },
    {
      name: t("Subcategory"),

      selector: (row) =>
        Cookies.get("i18next") === "ar"
          ? row.subCategory.nameAr
          : row.subCategory.nameEn,
      sortable: true,
    },
    // {
    //   name: t("product state"),

    //   selector: (row) => row.status === true ? t("active") : t("not active"),
    //   sortable: true,
    // },

    {
      name: t("price"),
      selector: (row) => row["price1"],
      sortable: true,
    },
    {
      name: t("rate"),
      selector: (row) => row["ratingsAverage"],
      cell: (row) => <div style={{ width: "auto" }}>{row.ratingsAverage}</div>,
      sortable: true,
    },

    {
      name: t("Number of rater"),
      cell: (row) => (
        <div
          style={{ cursor: "pointer" }}
          variant="secondary"
          onClick={() => navigate(`/admin/reviews/${row._id}`)}
          id={row._id}
        >
          {row.ratingsQuantity}
          <i className="fa fa-folder-open-o mx-1" />
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },

    {
      name: t("Modification"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => navigate(`/admin/editProduct/${row._id}`)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("product state"),
      cell: (row) =>
        row.status == true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
            {t("activation")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: products,
  };

  return (
    <div>
      <Helmet>
        <title>
          {` ${t("products")} - ${
            Cookies.get("i18next") === "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>
      <div className="d-flex justify-content-between">
        <div
          className="sub-tile"
          style={{ color: settingsRes?.data?.primaryColor }}
        >
          <div className="admin-content-text"> {t("Manage all products")}</div>
        </div>
        {showAddComponnet && (
          <AddButton
            title={t("add product")}
            navigateTo={`/admin/addproduct`}
          />
        )}
      </div>

      {card === true ? (
        <Row className="justify-content-center">
          {products ? (
            products.map((item, index) => (
              <AdminProductCard key={index} item={item} />
            ))
          ) : (
            <h3>{t("no products")} </h3>
          )}
        </Row>
      ) : (
        <>
        
          {showSelectStoreComponent===true && (
            <div className="w-100">
              <SuperAdminSelectStoreComponent />
            </div>
          )}
          <DataTableExtensions
            {...tableData}
            filterPlaceholder={t("search here")}
          >
            <DataTable
              noHeader
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default AdminProducts;
