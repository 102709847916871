import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import getStoreNameFromUrl from "../Utilities/getStoreNameFromUrl";
const storeName=getStoreNameFromUrl();
const AdminSideBar = () => {
  return (
    <div className="sidebar">
      <div className="d-flex flex-column">
        <Link to={`/admin/alluser`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text mt-3 border-bottom p-2 mx-auto text-center">
            اداره المستخدمين
          </div>
        </Link>
        <Link to={`/admin/orders`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
            اداره الطلبات
          </div>
        </Link>
        <Link to={`/admin/products`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
            اداره المنتجات
          </div>
        </Link>
        <Link to={`/admin/sliders`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
            اداره شريط الصور
          </div>
        </Link>
        <Link to={`/admin/addbrand`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
            اضف ماركه
          </div>
        </Link>
        <Link to={`/admin/addcategory`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
            اضف تصنيف
          </div>
        </Link>
        <Link to={`/admin/addsubcategory`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
            اضف تصنيف فرعي
          </div>
        </Link>
        <Link to={`/admin/addproduct`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
            اضف منتج
          </div>
        </Link>
        <Link to={`/admin/coupons`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
            اضف كوبون خصم
          </div>
        </Link>
        <Link to={`/admin/cartReport`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
            اداره السلل المتروكة
          </div>
        </Link>
        <Link to={`/admin/googleAnalytics`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
            تقرير تحليلات جوجل
          </div>
        </Link>
        <Link to={`/admin/productSalesReport`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
            تقرير المبيعات
          </div>
        </Link>
        <Link to={`/admin/terms`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
            الشروط و الاحكام
          </div>
        </Link>
        <Link to={`/admin/policy`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
            سياسة الاسترجاع
          </div>
        </Link>
        <Link to={`/admin/about`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
            عن ميجا ستور
          </div>
        </Link>

        <Link to={`/admin/settings`} style={{ textDecoration: "none" }}>
          <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center">
            الاعدادات
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AdminSideBar;
