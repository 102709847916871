import { use } from "i18next";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminOrderDetails from "../../Components/Admin/AdminOrderDetails";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import SideBar from "../../Components/Admin/SideBar";
import VendorSideBar from "../../Components/Vendor/VendorSideBar";

const AdminOrdersDetailsPage = () => {
  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }
  return (
    // <Container className="py-3">
    //   <Row className="py-3">
    //     <Col sm="3" xs="2" md="2">
    //     <SideBar />
    //     </Col>
    //     <Col sm="9" xs="10" md="10">
    //       <AdminOrderDetails />
    //     </Col>
    //   </Row>
    // </Container>
    <>
      {user.role === "admin" || user?.role === "superAdmin" ? (
        <div className="d-flex justify-content-start">
          <div>
            <SideBar />
          </div>
          <div className="p-4" style={{ width: "100%" }}>
            <AdminOrderDetails />
          </div>
        </div>
      ) : (
        <Container>
          <Row className="py-3">
            <Col sm="3" xs="2" md="2">
              {user.role == "admin" || user?.role === "superAdmin" ? (
                <SideBar />
              ) : (
                <VendorSideBar />
              )}
            </Col>
            <Col sm="9" xs="10" md="10">
              <AdminOrderDetails />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
export default AdminOrdersDetailsPage;
