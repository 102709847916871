import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getSalesReport,
} from "../../redux/actions/productsAction";
import { getSettings } from "../../redux/actions/settingsAcions";



const ViewSalesProductAdminHook = () => {
    const dispatch = useDispatch();
    const [from, setFrom] = useState(new Date().toISOString().split('T')[0]);
    const [to, setTo] = useState(new Date().toISOString().split('T')[0]);
    // const[totalPrice1,setTotalPrice1]=useState(0);
    const [zeroFlag, setZeroFlag] = useState(true);
    const user = JSON.parse(localStorage.getItem("user"));

    const storeIdRes = useSelector(
        (state) => state.allStores.selectStoreBySuperAdmin
      );

    const onChangeFrom = (e) => {
        setFrom(e.target.value);
    };
    const onChangeTo = (e) => {
        setTo(e.target.value);
    };

    useEffect(()=>{
        if(storeIdRes && user.role=="superAdmin") dispatch(getSalesReport(from, to, zeroFlag,storeIdRes));
    },[storeIdRes]);

    useEffect(() => {
        let storeId=user.role=="superAdmin" ? storeIdRes :user.storeId;
        dispatch(getSalesReport(from, to, zeroFlag,storeId));
    }, []);

    const handleSearchDate = async () => {
        let storeId=user.role=="superAdmin" ? storeIdRes :user.storeId;
        await dispatch(getSalesReport(from, to, zeroFlag,storeId));
    }

    const allproduct = useSelector((state) => state.allProduct.salesReport);
    const settingsRes = useSelector((state) => state.settingsReducers.settings);

    let items = [];
    if (allproduct)
        if (allproduct.data) items = allproduct.data;
        else items = [];

    const totalPrice1 = () => {
        //const totalPrice = items.reduce((a, b) => a + b.totalPrice1, 0);
        let totalPrice=0;
         totalPrice=items.forEach(el=>totalPrice+=Number(el.price1));
        console.log('totalPrice',totalPrice);
        return totalPrice
    }

    const totalPrice2 = () => {
        const totalPrice = items.reduce((a, b) => a + b.totalPrice2, 0);
        return totalPrice
    }

    return [
        items,
        from,
        onChangeFrom,
        to,
        onChangeTo,
        handleSearchDate,
       // totalPrice1,
       // totalPrice2,
        zeroFlag,
        setZeroFlag,
        settingsRes
    ];
};

export default ViewSalesProductAdminHook;
