import {
  ADD_COUPON,
  DELETE_COUPON,
  GET_ALL_COUPON,
  GET_ONE_COUPON,
  EDIT_COUPON,
} from "../type";
import { useInsertData } from "../../hooks/useInsertData";
import { useGetData, useGetDataToken } from "../../hooks/useGetData";
import useDeleteData from "../../hooks//useDeleteData";
import { useUpdateData } from "../../hooks/useUpdateData";

export const addCoupon = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/coupons`, body);

    dispatch({
      type: ADD_COUPON,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_COUPON,
      payload: error.response,
    });
  }
};

export const getAllCoupon = (storeId) => async (dispatch) => {
  try {
    const response = await useGetDataToken(
      `/api/v1/coupons?storeId=${storeId}`
    );

    dispatch({
      type: GET_ALL_COUPON,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_COUPON,
      payload: error.response,
    });
  }
};

export const deleteCoupon = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/coupons/${id}`);
    // console.log(response)
    dispatch({
      type: DELETE_COUPON,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: DELETE_COUPON,
      payload: error.response,
    });
  }
};

export const getOneCoupon = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/coupons/oneCoupon/${id}`);

    dispatch({
      type: GET_ONE_COUPON,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_COUPON,
      payload: error.response,
    });
  }
};

export const eidtCoupon = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/coupons/${id}`, body);

    dispatch({
      type: EDIT_COUPON,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: EDIT_COUPON,
      payload: error.response,
    });
  }
};
