import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editData, getData } from "../../redux/actions/termsPolicyAboutAction";
import notify from "../useNotification";

const TermsPolicyAboutHook = (type) => {
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingEdit, setLoadingEdit] = useState(true);
  const [showSelectStoreComponent, setShowSelectStoreComponent] =
    useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();

  const res = useSelector((state) => state.termsPolicyAboutReducer.data);
  
  const storeIdRes = useSelector(
    (state) => state.allStores.selectStoreBySuperAdmin
  );

  const storeNameResponse = useSelector((state) => state.allStores.oneStore);

  // console.log('storeNameResponse');
  // console.log(storeNameResponse);
  useEffect(()=>{
    if(storeIdRes){
      console.log('storeIdRes')
      dispatch(getData(type,storeIdRes ));
    } 
  },[storeIdRes]);

  useEffect(() => {
    const get = async () => {
      window.scrollTo(0, 0);
      setLoading(true);
      if(user?.role!=="superAdmin"){
        setShowSelectStoreComponent(false);
        await dispatch(getData(type, user?.storeId));
      }
      else{
        setShowSelectStoreComponent(true);
      }  

      // console.log('user in terms page');
      // console.log(user);
      if(user==null){
        await dispatch(getData(type, storeNameResponse._id));
      }

      setLoading(false);
    };

    get();
  }, [dispatch, type]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [type]);

  const onChangeNameAr = (e) => {
    e.persist();
    setNameAr(e.target.value);
  };
  const onChangeNameEn = (e) => {
    e.persist();
    setNameEn(e.target.value);
  };

  // useEffect(()=>{
  //   if (res && res.data) {
  //     setNameAr(res.data.nameAr);
  //     setNameEn(res.data.nameEn);
  //   }
  // },[storeIdRes]);
  useEffect(() => {
    // if (loading === false) {
      if (res && res.data) {
        setNameAr(res.data.nameAr);
        setNameEn(res.data.nameEn);
      }
    // }
  }, [loading,storeIdRes,res]);

  const editRes = useSelector(
    (state) => state.termsPolicyAboutReducer.editData
  );
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const onSubmit = async () => {
    setLoadingEdit(true);

    await dispatch(
    //  let store=user.role=="superAdmin"? 

      editData(type,user.role=="superAdmin"? storeIdRes : user?.storeId, {
        nameAr: nameAr,
        nameEn: nameEn,
      })
    );
    setLoadingEdit(false);
  };

  useEffect(() => {
    if (loadingEdit === false) {
      if (editRes && editRes.success === true) {
        notify("تم التعديل بنجاح", "success");
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loadingEdit]);

  return [
    nameAr,
    nameEn,
    onChangeNameAr,
    onChangeNameEn,
    onSubmit,
    settingsRes,
    showSelectStoreComponent,
    storeIdRes
  ];
};

export default TermsPolicyAboutHook;
