import React from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CartItem from "../../Components/Cart/CartItem";
import ChangeOrderStatusHook from "../../hook/admin/ChangeOrderStatusHook";
import GetOrderDetailsHook from "../../hook/admin/GetOrderDetailsHook";
import UserAllOrderItem from "../User/UserAllOrderItem";
import { ToastContainer } from "react-toastify";
import { t } from "i18next";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import LoadingBar from "react-top-loading-bar";
const AdminOrderDetails = () => {
  const { id } = useParams();
  const [orderData, cartItems, settingsRes, ref] = GetOrderDetailsHook(id);
  const { t } = useTranslation()

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };


  const [
    onChangePaid,
    changePayOrder,
    onChangeDelivered,
    changeDeliveredOrder,
    orderStatusRes,
    delivered,
    pay,
    show,
    handleClose,
    handleShow,
    description,
    onChangeDescription,
    showModalDescription,
    allOrderHistory,
    handleCloseModel,
    refundValue,
    onChangeRefundValue,
    onChangeRefundDescription,
    refundDescription
  ] = ChangeOrderStatusHook(id, orderData?.user?._id);



  const columns = [

    {
      name: t("Serial"),
      selector: "index",
      sortable: true,
    },
    {
      name: t("payment state"),
      selector: (row => row.paymentStatus == "true" ? t("Paid") : t("not paid")),
      sortable: true,
    },
    {
      name: t("order state"),
      selector: "orderStatus",
      sortable: true,
    },
    {
      name: t("the user"),
      selector: (row => row.user?.firstname + ' ' + row.user?.lastname),
      sortable: true,
    },
    {
      name: t("date"),
      selector: (row => formatDate(row.createdAt)),
      sortable: true,
    },
    {
      name: t("Notes"),
      selector: "desc",
      sortable: true,
    },


  ];

  const tableData = {
    columns,
    data: allOrderHistory.data,
  };

  console.log('orderData',orderData);
  
  return (
    <div>

      <Helmet>
        <title>{` ${t("Order Details No.")} ${orderData.orderNumber} -  ${Cookies.get("i18next") === "ar" ? settingsRes.data?.titleAr : settingsRes.data?.titleEn}`} </title>
      </Helmet>



      <Modal show={show} onHide={handleCloseModel}>
        <Modal.Header >
          <Modal.Title>

            <div className="font">{t("Do you want to add notes")}</div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            <textarea
              id="description"
              onChange={onChangeDescription}
              value={description}
              className="input-form-area p-2 mt-2"
              rows="4"
              cols="50"
              placeholder={t("add notes")}
            />
          </div>
          <Row className="justify-content-start ">
            {
              delivered === "63c7c73b643a9581a2d252ec" ? <Col sm="12">
                <label htmlFor="refundValue" className="d-block mt-2 ">
                  {" "}
                  {t("refundValue")}{" "}
                </label>
                <input
                  id="refundValue"
                  value={refundValue}
                  onChange={onChangeRefundValue}
                  type="number"
                  className="input-form d-block mt-2 px-3"
                  placeholder={t("refundValue")}
                />
                {/* <label htmlFor="type" className="d-block mt-2 ">
                {" "}
                {t("type")}{" "}
              </label> */}
                {/* <select
                // value={type}
                // onChange={onChangeType}
                name="type"
                id="type"
                className="select input-form-area mt-2 px-3 "
              >
                <option value="0">{t("type")}</option>
                <option value="1">{t("deposit")}</option>
                <option value="2">{t("withdraw")}</option>
              </select> */}
                <label htmlFor="refundDescription" className="d-block mt-2 ">
                  {t("refundDescription")}
                </label>
                <textarea
                  id="refundDescription"
                  onChange={onChangeRefundDescription}
                  value={refundDescription}
                  className="input-form-area p-2 mt-2"
                  rows="4"
                  cols="50"
                  placeholder={t("refundDescription")}
                />
              </Col>
                : null
            }
          </Row>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" className="font" onClick={() => (handleClose())}>
            {t("Save Changes")}
          </Button>
        </Modal.Footer>
      </Modal>
      <LoadingBar color={settingsRes?.data?.primaryColor} ref={ref} shadow={true} height={4} />

      <div className="admin-content-text">
        {`${t("Order Details No.")} ${orderData.orderNumber || ""} `}
      </div>
      <UserAllOrderItem orderItem={orderData} />
      <Row className=" my-2 pb-3 user-data h-100">
        <Col xs="12" className=" d-flex">
          <div className="admin-content-text py-2 ">{t("Customer Details")} </div>
        </Col>

        <Col xs="12" className="d-flex">
          <div
            style={{
              color: "#555550",
              fontFamily: "Almarai",
              fontSize: "16px",
            }}
          >
            {t("name")}:
          </div>

          <div
            style={{
              color: "#979797",
              fontFamily: "Almarai",
              fontSize: "16px",
            }}
            className="mx-2"
          >
            {`${(orderData.user && orderData.user.firstname) || ""} ${(orderData.user && orderData.user.lastname) || ""
              }`}
          </div>
        </Col>

        <Col xs="12" className="d-flex">
          <div
            style={{
              color: "#555550",
              fontFamily: "Almarai",
              fontSize: "16px",
            }}
          >
            {t("phone number")}:
          </div>

          <div
            style={{
              color: "#979797",
              fontFamily: "Almarai",
              fontSize: "16px",
            }}
            className="mx-2"
          >
            {orderData.user && orderData.user.mobile}
          </div>
        </Col>
        <Col xs="12" className="d-flex">
          <div
            style={{
              color: "#555550",
              fontFamily: "Almarai",
              fontSize: "16px",
            }}
          >
            {t("email")}:
          </div>

          <div
            style={{
              color: "#979797",
              fontFamily: "Almarai",
              fontSize: "16px",
            }}
            className="mx-2"
          >
            {orderData.user && orderData.user.email}
          </div>
        </Col>
        <Col xs="12" className=" d-flex">
          <div className="admin-content-text py-2">{t("Client's Address")} </div>
        </Col>
        <Col xs="12" className="d-flex">
          <div
            style={{
              color: "#555550",
              fontFamily: "Almarai",
              fontSize: "16px",
            }}
          >
            {t("address name")}:
          </div>
          <div
            style={{
              color: "#979797",
              fontFamily: "Almarai",
              fontSize: "16px",
            }}
            className="mx-2"
          >
            {`${(orderData.userAddress && orderData.userAddress.address) || ""}`}
          </div>
        </Col>
        {settingsRes?.data?.showAddressDetails ? <>
          <Col xs="12" className="d-flex">
            <div
              style={{
                color: "#555550",
                fontFamily: "Almarai",
                fontSize: "16px",
              }}
            >
              {t("nearest mark")}:
            </div>
            <div
              style={{
                color: "#979797",
                fontFamily: "Almarai",
                fontSize: "16px",
              }}
              className="mx-2"
            >
              {`${(orderData.userAddress && orderData.userAddress.closeSign) || ""}`}
            </div>
          </Col>
          <Col xs="12" className="d-flex">
            <div
              style={{
                color: "#555550",
                fontFamily: "Almarai",
                fontSize: "16px",
              }}
            >
              {t("zone")}:
            </div>
            <div
              style={{
                color: "#979797",
                fontFamily: "Almarai",
                fontSize: "16px",
              }}
              className="mx-2"
            >
              {`${(orderData.userAddress && orderData.userAddress.zone) || ""}`}
            </div>
          </Col>

          <Col xs="12" className="d-flex">
            <div
              style={{
                color: "#555550",
                fontFamily: "Almarai",
                fontSize: "16px",
              }}
            >
              {t("phone number")}:
            </div>
            <div
              style={{
                color: "#979797",
                fontFamily: "Almarai",
                fontSize: "16px",
              }}
              className="mx-2"
            >
              {`${(orderData.userAddress && orderData.userAddress.mobile) || ""}`}
            </div>
          </Col>
        </> : ''}


        <Col xs="12" className="d-flex">
          <div
            style={{
              color: "#555550",
              fontFamily: "Almarai",
              fontSize: "16px",
            }}
          >
            {t("Client address link")} :
          </div>
          <a href={`https://www.google.com/maps/search/?api=1&query=${orderData?.userAddress?.latitude},${orderData?.userAddress?.longitude}`} target="_blank" rel="noreferrer" className="mx-2" style={{ color: settingsRes?.data?.primaryColor, fontSize: '17px' }}>
            {t("from here")}
          </a>
        </Col>



        <div className="d-flex mt-2 justify-content-center">
          <label htmlFor="paid" className="mt-3 mx-3 " style={{ fontWeight: 'bold' }}>   {t("payment state")} </label>
          <div className="d-flex mt-2 justify-content-center">
            <select
              name="pay"
              id="paid"
              className="select input-form-area mt-1  text-center px-2 w-75"
              onChange={onChangePaid}
              value={pay}
            >
              {/* <option value="0"> الدفع</option> */}
              <option value="true">{t("paid")}</option>
              <option value="false">{t("not paid")}</option>
            </select>
            <button
              onClick={() => (showModalDescription(1))}
              className="btn-a px-3 d-inline mx-2 " style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            >
              {t("save")}{" "}
            </button>
          </div>
          <div className="d-flex mt-2 justify-content-center">
            <label htmlFor="lang" className="mt-2 mx-3 " style={{ fontWeight: 'bold' }}> {t("order state")} </label>
            <select
              name="languages"
              id="lang"
              className="select input-form-area mt-1  text-center px-2 w-50"
              onChange={onChangeDelivered}
              value={delivered}
            >
              {/*<option value="0"> التوصيل</option> */}
              {orderStatusRes.data
                ? orderStatusRes.data.map((el) => {
                  return (
                    <option key={el._id} value={el._id}>

                      {Cookies.get("i18next") === "ar" ? el.nameAr : el.nameEn}
                    </option>
                  );
                })
                : null}
            </select>
            <button
              onClick={() => (showModalDescription(2))}
              className="btn-a px-3 d-inline mx-2 " style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            >
              {t("save")}{" "}
            </button>
          </div>
        </div>


      </Row>

      <Row className=" my-2 pb-3 user-data h-100">
        <div className="admin-content-text py-2" style={{ float: 'left' }}> {t("Summary of order states")}</div>
        <DataTableExtensions {...tableData} filterPlaceholder={t("save")}>
          <DataTable
            noHeader
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>


      </Row>



      <ToastContainer />
    </div>
  );
};

export default AdminOrderDetails;

