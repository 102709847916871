import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import ViewAllOrdersVendorHook from "../../hook/vendor/ViewAllOrdersVendorHook";
import DataTableExtensions from "react-data-table-component-extensions";
import { useDispatch, useSelector } from "react-redux";
import { sendEmail } from "../../redux/actions/cartAction";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import getStoreNameFromUrl from "../Utilities/getStoreNameFromUrl";

const storeName=getStoreNameFromUrl();


const VendorAllOrder = () => {
  const { t } = useTranslation()
  const [AllOrdersVendorById] = ViewAllOrdersVendorHook();

  const navigate = useNavigate()
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const [show, setShow] = useState(false);

  const dispatch = useDispatch()
  const handleClose = () => {
    setShow(false)
  }
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const onChangeSubject = (e) => {
    e.persist();
    setSubject(e.target.value);
  };
  const onChangeText = (e) => {
    e.persist();
    setText(e.target.value);
  };

  const handleSendEmail = async () => {
    if (subject === "" || text === "") {
      notify(t("please complete the data"), "warn");
      return;
    } else {
      setLoading(true);
      await dispatch(sendEmail({
        subject,
        email,
        text
      }))
      setLoading(false);
      setShow(false)
    }
  }
  const res = useSelector((state) => state.cartReducer.sendEmail);

  useEffect(() => {

    if (loading === false) {
      setEmail("");
      setSubject("");
      setText("");
      if (res && res.success === true) {
        notify(t("Email sent successfully"), "success");
      }
    }
  }, [loading]);

  const columns = [
    {
      name: t("order number"),
      selector: "orderNumber",
      sortable: true,
    },
    // {
    //   name: "اسم المستخدم",
    //   selector: (row) => row.user.firstname,
    //   sortable: true,
    // },
    {
      name: t("user name"),
      sortable: true,
      cell: (row) => (
        <span
          variant="secondary"
          onClick={() => navigate(`/admin/editUser/${row.user._id}`)}
          id={row._id}
          style={{
            fontSize: '14px',
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
        >
          {row.user.firstname}
        </span >
      ),
    },
    {
      name: t("user phone number"),
      selector: (row) => row.user.mobile,
      sortable: true,
    },
    {
      name: t("total"),
      selector: "totalPrice",
      sortable: true,
    },
    {
      name: t("order state"),
      selector: (row) => row.orderStatusId.nameAr,
      sortable: true,
    },
    {
      name: t("date"),
      selector: (row) => formatDate(row.createdAt),
      sortable: true,
    },
    {
      name: t("send email"),
      cell: (row) => (
        <Button
          variant="success"
          onClick={() => {
            setShow(true)
            setEmail(row.user.email)
          }}
          id={row._id}
          style={{
            fontSize: '14px'
          }}
        >
          {t("send email")}
        </Button >
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("details"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => navigate(`/vendor/orders/${row._id}`)}
          id={row._id}
          style={{
            fontSize: '14px'
          }}
        >
          {t("details")}
        </Button >
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

  ];

  const tableData = {
    columns,
    data: AllOrdersVendorById,
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("send emails")}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            <input
              value={subject}
              onChange={onChangeSubject}
              type="text"
              className="input-form d-block mt-3 px-3"
              placeholder={t("address")}
            />
            <textarea
              value={text}
              onChange={onChangeText}
              className="input-form-area p-2 mt-3"
              rows="4"
              cols="50"
              placeholder={t("details")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="font" variant="secondary" onClick={handleClose}>
            {t("retreat")}
          </Button>
          <Button className="font" variant="success" onClick={handleSendEmail}>
            {t("send")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="admin-content-text">{t("Management all order")}</div>

      <DataTableExtensions {...tableData} filterPlaceholder="ابحث هنا">
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />

      {/* <Row className="justify-content-center">
        {orderData.length >= 1 ? (
          orderData.map((el) => {
            return <AdminOrderItem key={el._id} orderItem={el} />;
          })
        ) : (
          <h6>لا يوجد طلبات حتي الان</h6>
        )}
      </Row> */}
      {/* <Pagination pageCount={noPages || 0} onPress={onPress} /> */}
    </div>
  );


}

export default VendorAllOrder