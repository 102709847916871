import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import AdminProductCard from "./AdminProductCard";
import DataTable from "react-data-table-component";
//  import { t } from "i18next";
import Cookies from "js-cookie";
import DataTableExtensions from "react-data-table-component-extensions";
import { useNavigate } from "react-router-dom";

import add from "../../Images/add.png";
import { ToastContainer } from "react-toastify";
import AddButton from "../Utilities/AddButton";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ExportProductsFromDropShippingHook from "../../hook/product/ExportProductsFromDropShippingHook";
import { useSelector } from "react-redux";
import Select from "react-select";

const AdminProductsFromDropShipping = () => {
  const { t } = useTranslation();

  const [
    values,
    onChangeValues,
    user,
    allVendorsResponse,
    settingsRes,
    webSiteData,
    category,
    getProductsFromDropShippingByCategory,
    onChangeCategory,
    allProductsCategory,
    onSubmit,
  ] = ExportProductsFromDropShippingHook();

  //let columns=[];
  //let tableData;

  const columns = [
    {
      name: t("image"),
      cell: (row) => (
        <img
          src={row.image}
          width="50px"
          alt="productImage"
          height="50px"
          style={{
            padding: "2px",
            justifyContent: "center",
            alignItems: "center",
            objectFit: "contain",
          }}
          loading="lazy"
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("product name"),

      selector: (row) =>
        Cookies.get("i18next") === "ar" ? row["nameAr"] : row["nameEn"],
      cell: (row) => (
        <div
          title={
            Cookies.get("i18next") === "ar" ? row["nameAr"] : row["nameEn"]
          }
          style={{
            width: "85%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            height: "15px",
          }}
        >
          {Cookies.get("i18next") === "ar" ? row["nameAr"] : row["nameEn"]}
        </div>
      ),
      sortable: true,
    },

    {
      name: t("category"),

      selector: (row) =>
        Cookies.get("i18next") === "ar"
          ? row.category?.nameAr
          : row.category.nameEn,
      sortable: true,
    },
    {
      name: t("Subcategory"),

      selector: (row) =>
        Cookies.get("i18next") === "ar"
          ? row.subCategory.nameAr
          : row.subCategory.nameEn,
      sortable: true,
    },

    {
      name: t("price"),
      selector: (row) => row["price1"],
      sortable: true,
    },
  ];

  const tableData = {
    columns,
    data: allProductsCategory,
  };

  // if(values.show)
  // {
  //   columns = [
  //     {
  //       name: t("image"),
  //       cell: (row) => (
  //         <img
  //             src={row.image}
  //             width="50px"
  //             alt="productImage"
  //             height="50px"
  //             style={{
  //               padding: "2px",
  //               justifyContent: "center",
  //               alignItems: "center",
  //               objectFit: "contain",
  //             }}
  //             loading="lazy"

  //           />

  //       ),
  //       ignoreRowClick: true,
  //       allowOverflow: true,
  //       button: true,
  //     },
  //     {
  //       name: t("product name"),

  //       selector:row => Cookies.get("i18next") === "ar" ? row["nameAr"] : row["nameEn"],
  //       cell:(row)=><div title={Cookies.get("i18next") === "ar" ? row["nameAr"] : row["nameEn"]}  style={{ width: '85%',textOverflow:'ellipsis',overflow:'hidden' ,height:'15px' }}>{Cookies.get("i18next") === "ar" ? row["nameAr"] : row["nameEn"]}</div>,
  //       sortable: true,
  //     },

  //     {
  //       name: t("category"),

  //       selector: (row) => Cookies.get("i18next") === "ar" ? row.category?.nameAr : row.category.nameEn,
  //       sortable: true,
  //     },
  //     {
  //       name: t("Subcategory"),

  //       selector: (row) => Cookies.get("i18next") === "ar" ? row.subCategory.nameAr : row.subCategory.nameEn,
  //       sortable: true,
  //     },

  //     {
  //       name: t("price"),
  //       selector:row => row["price1"] ,
  //       sortable: true,
  //     },

  //   ];

  //   tableData = {
  //     columns,
  //     data: allProductsCategory,
  //   };

  // }

  return (
    <div>
      <Helmet>
        <title>
          {" "}
          {`${t("addProductsDropShippings")}-${
            Cookies.get("i18next") === "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>

      <Row className="justify-content-start">
        <Col sm="8">
          <div className="admin-content-text pb-4">
            {t("addProductsDropShippings")}
          </div>

          <label htmlFor="mainCategory" className="d-block mt-2 ">
            {t("main classification")}
          </label>

          <Select
            defaultOptions
            name="mainCategory"
            onChange={onChangeCategory}
            getOptionLabel={(el) =>
              Cookies.get("i18next") === "ar" ? el.nameAr : el.nameEn
            }
            getOptionValue={(e) => e._id}
            placeholder={t("main classification")}
            options={category.data}
            isClearable
          />

          {webSiteData.MULTI_VENDOR === "true" &&
            (user?.role === "admin" || user.role === "superAdmin") && (
              <div>
                <label htmlFor="selectedVendor" className="d-block mt-2 ">
                  {t("select vendor")}
                </label>

                <select
                  name="selectedVendor"
                  value={values.selectedVendor}
                  onChange={onChangeValues}
                  className="select input-form-area mt-2 px-3 "
                >
                  <option value="0">{t("select vendor")}</option>
                  {allVendorsResponse
                    ? allVendorsResponse.map((el) => {
                        return (
                          <option key={el._id} value={el._id}>
                            {el?.firstname} {el?.lastname}
                          </option>
                        );
                      })
                    : null}
                </select>
              </div>
            )}
          {values.show ? (
            <>
              <DataTableExtensions
                {...tableData}
                filterPlaceholder={t("search here")}
              >
                <DataTable
                  noHeader
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
            </>
          ) : null}
        </Col>
      </Row>

      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          {values.show == false ? (
            <>
              <button
                className="btn-save d-inline mt-2 "
                style={{ backgroundColor: settingsRes?.data?.primaryColor }}
                onClick={getProductsFromDropShippingByCategory}
              >
                {t("getProducts")}
              </button>
            </>
          ) : null}
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminProductsFromDropShipping;
