import {
  GET_ALL_STORES,
  GET_ONE_STORE_BY_NAME,
  ONE_STORE,
  SELECT_STORE_BY_SUPER_ADMIN,
  UPDATE_STORE,
  iS_UPDATING,
  UPDATE_STORENAME
} from "../type";

const initialState = {
  allStores: [],
  oneStore: {},
  selectStoreBySuperAdmin: "",
  updatedStore:{},
  isUpdating:false,
  loading: true,
  oneStoreById: [],
  newStoreName:{}
};

const storesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STORES:
      return {
        ...state,
        allStores: action.payload,
        loading: false,
      };
    case GET_ONE_STORE_BY_NAME:
      return {
        ...state,
        oneStore: action.payload,
        loading: false,
      };
    case SELECT_STORE_BY_SUPER_ADMIN:
      return {
        ...state,
        selectStoreBySuperAdmin: action.payload,
        loading: false,
      };
    case ONE_STORE:
      return {
        ...state,
        oneStoreById: action.payload,
        loading: false,
      };
      case UPDATE_STORE:
        return{
          ...state,
          updatedStore:action.payload,
          isUpdating:false
        }
      case iS_UPDATING:
        return{
          ...state,
          isUpdating:true
        }
      case UPDATE_STORENAME:
        return{
          ...state,
          newStoreName:action.payload
        }
    default:
      return state;
  }
};

export default storesReducer;
