import React, { useState, useEffect } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import AdminAllOrdersHook from "../../hook/admin/AdminAllOrdersHook";
//import ViewSalesProductAdminHook from "../../hook/admin/ViewSalesProductAdminHook";
import AdminOrderItem from "./AdminOrderItem";
import Pagination from "../../Components/Utilities/Pagination";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import DataTableExtensions from "react-data-table-component-extensions";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import { sendEmail } from "../../redux/actions/cartAction";
import { Helmet } from "react-helmet";
import getStoreNameFromUrl from "../Utilities/getStoreNameFromUrl";
import SuperAdminSelectStoreComponent from "../Utilities/SuperAdminSelectStoreComponent";
// const [
//   items,
//   from,
//   onChangeFrom,
//   to,
//   onChangeTo,
//   handleSearchDate,
//   totalPrice1,
//   totalPrice2,
//   zeroFlag,
//   setZeroFlag,
//   settingsRes

// ] = ViewSalesProductAdminHook();
const storeName = getStoreNameFromUrl();

const AdminOrders = () => {
  const { t } = useTranslation();
  const [
    username,
    result,
    orderData,
    noPages,
    onPress,
    webSiteData,
    settingsRes,
    from,
    to,
    onChangeTo,
    onChangeFrom,
    handleSearchDate,
    couponSearch,
    onChangeCouponSearch,
    handleSearchCoupon,
    handleRefresh,
    showSelectStoreComponent,
  ] = AdminAllOrdersHook();
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const handleClose = () => {
    setShow(false);
  };
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);

  const onChangeSubject = (e) => {
    e.persist();
    setSubject(e.target.value);
  };
  const onChangeText = (e) => {
    e.persist();
    setText(e.target.value);
  };

  const handleSendEmail = async () => {
    if (subject === "" || text === "") {
      notify(t("please complete the data"), "warn");
      return;
    } else {
      setLoading(true);
      await dispatch(
        sendEmail({
          subject,
          email,
          text,
        })
      );
      setLoading(false);
      setShow(false);
    }
  };
  const res = useSelector((state) => state.cartReducer.sendEmail);

  useEffect(() => {
    if (loading === false) {
      setEmail("");
      setSubject("");
      setText("");
      if (res && res.success === true) {
        notify(t("Email sent successfully"), "success");
      }
    }
  }, [loading]);

  // useEffect(() => {
  //   if (webSiteData.MULTI_VENDOR === 'true') {
  //     columns.unshift({
  //       name: t("vendor name"),
  //       selector: (row) => row.vendor.firstname + ' ' + row.vendor.lastname,
  //       sortable: true,
  //     })
  //   }
  // }, [orderData])

  const columns = [
    {
      name: t("order number"),
      selector: "orderNumber",
      sortable: true,
    },
    // {
    //   name: "اسم المستخدم",
    //   selector: (row) => row.user.firstname,
    //   sortable: true,
    // },

    {
      name: t("user name"),
      sortable: true,
      cell: (row) => (
        <span
          variant="secondary"
          onClick={() => navigate(`/admin/editUser/${row.user._id}`)}
          id={row._id}
          style={{
            fontSize: "14px",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {row.user.firstname}
        </span>
      ),
    },

    {
      name: t("phone number"),
      selector: (row) => row?.user?.mobile,
      sortable: true,
    },
    {
      name: t("total"),
      selector: "totalPrice",
      sortable: true,
    },
    {
      name: t("order state"),
      selector: (row) => row?.orderStatusId?.nameAr,
      sortable: true,
    },
    {
      name: t("copon"),
      selector: (row) => row?.coupon?.title,
      sortable: true,
    },
    {
      name: t("date"),
      selector: (row) => formatDate(row.createdAt),
      sortable: true,
    },
    {
      name: t("send email"),
      cell: (row) => (
        <Button
          variant="success"
          onClick={() => {
            setShow(true);
            setEmail(row?.user?.email);
          }}
          id={row._id}
          style={{
            fontSize: "14px",
          }}
        >
          {t("send email")}
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("details"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => navigate(`/admin/orders/${row._id}`)}
          id={row._id}
          style={{
            fontSize: "14px",
          }}
        >
          {t("details")}
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: orderData,
  };

  return (
    <div>
      <Helmet>
        <title>
          {` ${t("orders")} -  ${
            Cookies.get("i18next") === "ar"
              ? settingsRes?.data?.titleAr
              : settingsRes?.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("send emails")}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            <input
              value={subject}
              onChange={onChangeSubject}
              type="text"
              className="input-form d-block mt-3 px-3"
              placeholder={t("address")}
            />
            <textarea
              value={text}
              onChange={onChangeText}
              className="input-form-area p-2 mt-3"
              rows="4"
              cols="50"
              placeholder={t("details")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="font" variant="secondary" onClick={handleClose}>
            {t("retreat")}
          </Button>
          <Button className="font" variant="success" onClick={handleSendEmail}>
            {t("send")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="admin-content-text">{t("order management")}</div>
      <div className="d-inline d-flex justify-content-start ">
        <input
          value={from}
          type="date"
          className="input-input-form d-block mx-1 mt-3 px-3"
          placeholder={t("from")}
          onChange={onChangeFrom}
        />
        <input
          value={to}
          type="date"
          className="input-input-form d-block mx-1 mt-3 px-3"
          placeholder={"to"}
          onChange={onChangeTo}
        />

        <Button
          onClick={handleSearchDate}
          className="mx-1 mt-3 px-3"
          style={{ backgroundColor: settingsRes?.data?.primaryColor }}
        >
          {t("search")}
        </Button>
      </div>
      <div className="d-inline d-flex justify-content-start ">
        <input
          value={couponSearch}
          type="text"
          className="input-input-form d-block mx-1 mt-3 px-3"
          placeholder={t("copon")}
          onChange={onChangeCouponSearch}
        />
        <Button
          onClick={handleSearchCoupon}
          className="mx-1 mt-3 px-3"
          style={{ backgroundColor: settingsRes?.data?.primaryColor }}
        >
          {t("search")}
        </Button>
        <Button
          onClick={handleRefresh}
          className="mx-1 mt-3 px-3"
          style={{ backgroundColor: settingsRes?.data?.primaryColor }}
        >
          <i className="fa fa-refresh"></i>
        </Button>
      </div>

      {showSelectStoreComponent === true && <SuperAdminSelectStoreComponent />}

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>

      <ToastContainer />
    </div>
  );
};

export default AdminOrders;
