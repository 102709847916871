import {
  CREATE_NEW_SLIDER,
  GET_ALL_SLIDER,
  DELETE_SLIDER,
  UPDATE_SLIDER,
  GET_ONE_SLIDER,
  ALL_SUBSCRIPTIONS,
  CREATE_NEW_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  PAYMENT_SUBSCRIPTION,
  LAST_SUBSCRIPTION,
  LAST_SUBSCRIPTION_LOADING_START,
  GET_LAST_SUBSCRIPTION_BY_ADMIN
} from "../type";
import { useInsertData } from "../../hooks/useInsertData";
import { useGetData } from "../../hooks/useGetData";
import useDeleteData from "../../hooks/useDeleteData";
import { useUpdateData } from "../../hooks/useUpdateData";

export const createNewSubscription = (data) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/subscriptions`, data);

    dispatch({
      type: CREATE_NEW_SUBSCRIPTION,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: CREATE_NEW_SUBSCRIPTION,
      payload: error,
    });
  }
};

export const getAllSubscription = (storeId) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/subscriptions/store/${storeId}`);

    dispatch({
      type: ALL_SUBSCRIPTIONS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ALL_SUBSCRIPTIONS,
      payload: error,
    });
  }
};

export const deleteSubsctiption = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/subscriptions/${id}`);

    dispatch({
      type: DELETE_SUBSCRIPTION,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: DELETE_SUBSCRIPTION,
      payload: error,
    });
  }
};

export const updateSubscription = (id, data) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/subscriptions/${id}`, data);

    dispatch({
      type: UPDATE_SUBSCRIPTION,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_SUBSCRIPTION,
      payload: error,
    });
  }
};

export const paymentSunscription = (data) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/subscriptions/payment`, data);

    dispatch({
      type: PAYMENT_SUBSCRIPTION,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: PAYMENT_SUBSCRIPTION,
      payload: error,
    });
  }
};

// get last subscription
export const getLastSubscription = (id) => async (dispatch) => {
  try {
    // console.log("id");
    // console.log(id);

    dispatch({
      type: LAST_SUBSCRIPTION_LOADING_START,
      // payload: {},
      loading: true,
    });
    
    const response = await useGetData(`/api/v1/subscriptions/last/store/${id}`);

    dispatch({
      type: LAST_SUBSCRIPTION,
      payload: response,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LAST_SUBSCRIPTION,
      payload: error,
    });
  }
};

//get last subscription by admin or superadmin
export const getLastSubscriptionForAdmin=(id) =>async(dispatch) =>{
  //GET_LAST_SUBSCRIPTION_BY_ADMIN
  try {
    // console.log("id");
    // console.log(id);

    dispatch({
      type: LAST_SUBSCRIPTION_LOADING_START,
      // payload: {},
      loading: true,
    });
    
    const response = await useGetData(`/api/v1/subscriptions/adminlaststore/${id}`);

    dispatch({
      type: GET_LAST_SUBSCRIPTION_BY_ADMIN,
      payload: response,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: GET_LAST_SUBSCRIPTION_BY_ADMIN,
      payload: error,
    });
  }
}
