import { GET_SETTINGS, EDIT_SETTINGS, GET_WEBSITE_DATA } from "../type";
import {
  useInsertDataWithImage,
  useInsertData,
} from "../../hooks/useInsertData";
import { useGetData } from "../../hooks/useGetData";

export const getSettings = (storeId) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/settings?storeId=${storeId}`);

    dispatch({
      type: GET_SETTINGS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_SETTINGS,
      payload: error,
    });
  }
};

export const getWebsiteData = () => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/settings/getWebsiteData`);

    dispatch({
      type: GET_WEBSITE_DATA,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_WEBSITE_DATA,
      payload: error,
    });
  }
};

export const editSettings = (body) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage(
      `/api/v1/settings/edit`,
      body
    );

    dispatch({
      type: EDIT_SETTINGS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: EDIT_SETTINGS,
      payload: error,
    });
  }
};
