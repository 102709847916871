import { GET_POLICY_TERMS_ABOUT, UPDATE_TERMS_ABOUT_POLICY } from "../type";
import { useGetData, useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";

export const getData = (type, storeId) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/terms/${type}?storeId=${storeId}`
    );

    dispatch({
      type: GET_POLICY_TERMS_ABOUT,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_POLICY_TERMS_ABOUT,
      payload: error,
    });
  }
};

export const editData = (type, storeId, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(
      `/api/v1/terms/${type}?storeId=${storeId}`,
      body
    );

    dispatch({
      type: UPDATE_TERMS_ABOUT_POLICY,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_TERMS_ABOUT_POLICY,
      payload: error,
    });
  }
};
