import axios from "axios";

// const baseURL = axios.create({ baseURL: "http://shelly-ksa.com" });

// const baseURL = axios.create({ baseURL: "http://demo.megastore-app.com:3000" });

// const baseURL = axios.create({ baseURL: "https://abayatlyan.com" });
//store-api.megastore-app.com
//http://store-api.megastore-app.com:5001

const baseURL = axios.create({ baseURL: "https://megastore-app.store:5001" });

//const baseURL = axios.create({ baseURL: "http://localhost" });

// const baseURL = axios.create({ baseURL: "https://abu6.com" });

// const baseURL = axios.create({ baseURL: "http://167.172.53.107" });

// const baseURL = axios.create({ baseURL: "http://143.110.174.151" });

export default baseURL;
