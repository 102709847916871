import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { createNewAds } from "../../redux/actions/adsAction";
import { useNavigate } from "react-router-dom";
import getStoreNameFromUrl from "../../Components/Utilities/getStoreNameFromUrl";

const storeName = getStoreNameFromUrl();

const AdminAddAdsHook = (id) => {
  const dispatch = useDispatch();

  // const [image, setimage] = useState(avatar);
  // const [selectedFile, setSelectedFile] = useState(null);

  // const [image2, setimage2] = useState(avatar);
  // const [selectedFile2, setSelectedFile2] = useState(null);

  const [title, settitle] = useState("");
  const [page, setpage] = useState("home_screen");
  const [key, setkey] = useState(0);
  const [loading, setLoading] = useState(true);
  const [check, setCheck] = useState(false);
  const [link, setLink] = useState("");
  const [link2, setLink2] = useState("");
  const [images, setImages] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");

  // show color picker
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState("");

  // show second color picker
  const [showSecondPicker, setShowSecondPicker] = useState(false);
  const [secondaryColor, setSecondaryColor] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  const onChangeKey = (e) => {
    setkey(e.target.value);
  };
  const onChangeTitle = (e) => {
    settitle(e.target.value);
  };
  const onChangePage = (e) => {
    setpage(e.target.value);
  };

  //on change store
  const changeStore = (e) => {
    console.log("e.target.value");
    console.log(e.target.value);
    setSelectedStore(e.target.value);
  };

  //all store response
  const allStoresResponse = useSelector((state) => state.allStores.allStores);

  // const onImageChange = (event) => {
  //   if (event.target.files && event.target.files[0])
  //     setimage(URL.createObjectURL(event.target.files[0]));
  //   setSelectedFile(event.target.files[0]);
  // };

  // const onImage2Change = (event) => {
  //   if (event.target.files && event.target.files[0])
  //     setimage2(URL.createObjectURL(event.target.files[0]));
  //   setSelectedFile2(event.target.files[0]);
  // };
  const onChangeLink = (e) => {
    setLink(e.target.value);
  };

  const onChangeLink2 = (e) => {
    setLink2(e.target.value);
  };

  const onChangePicker = (e) => {
    setShowPicker(!showPicker);
  };
  const onChangeSecondPicker = (e) => {
    setShowSecondPicker(!showSecondPicker);
  };

  const handleChaneComplete = (color) => {
    setColor(color.hex);
    setShowPicker(!showPicker);
  };
  const handleChaneCompleteSecond = (color) => {
    setSecondaryColor(color.hex);
    setShowSecondPicker(!showSecondPicker);
  };

  //to convert base 64 to file
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const itemImages = Array.from(Array(Object.keys(images).length).keys()).map(
    (item, index) => {
      return dataURLtoFile(images[index], Math.random() + ".png");
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (key == 0 || images === [] || page === "") {
      notify("من فضلك اكمل البيانات", "warn");
      return;
    }
    if (user?.role === "superAdmin" && selectedStore === "") {
      notify("من فضلك تأكد من اختيار المتجر", "error");
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("key", key);
    // formData.append("image", selectedFile);
    // formData.append("image", selectedFile2);
    itemImages.map((item) => formData.append("images", item));
    formData.append("color", color);
    formData.append("page", page);
    formData.append("secondaryColor", secondaryColor);
    formData.append("link", link);
    formData.append("link2", link2);
    formData.append(
      "storeId",
      user?.role === "superAdmin" ? selectedStore : user?.storeId
    );

    setCheck(true);
    setLoading(true);
    await dispatch(createNewAds(formData));
    setLoading(false);
    setCheck(false);
  };

  const sliderRes = useSelector((state) => state.adsReducers.newAds);
  useEffect(() => {
    if (loading === false) {
      if (sliderRes) {
        if (sliderRes.status === 201) {
          notify("تم الاضافة بنجاح", "success");
          setTimeout(() => {
            navigate(`/admin/ads`);
          }, 1000);
        } else {
          notify("فشل في عملية الاضافه", "error");
        }
      }
    }
  }, [loading]);

  return [
    title,
    key,
    showPicker,
    color,
    handleChaneComplete,
    handleSubmit,
    onChangeTitle,
    onChangeKey,
    onChangePicker,
    check,
    page,
    onChangePage,
    showSecondPicker,
    secondaryColor,
    handleChaneCompleteSecond,
    onChangeSecondPicker,
    link,
    onChangeLink,
    onChangeLink2,
    link2,
    images,
    setImages,
    user,
    allStoresResponse,
    changeStore,
  ];
};

export default AdminAddAdsHook;
