import { Helmet } from "react-helmet";
import AdminRefCodesHook from "../../hook/admin/AdminRefCodesHook";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Button, Modal } from "react-bootstrap";

import { ToastContainer } from "react-toastify";

export default function AdminRefCodes() {
  const { t } = useTranslation();
  const [codes, settingsRes,showModal,setShowModal,name,setName
    ,code,setCode,addNewCode,editCode] = AdminRefCodesHook();
    let baseUrl = window.location.host;
  console.log('allRefCodes');
  console.log(codes);

  if (codes.length == 0) return <LoadingSpinner />;

  let columns = [
    {
      name: 'اسم الموظف او الحملة',
      selector: 'name',
      sortable: true
    },
    {
      name: 'الكود',
      selector: 'code',
      sortable: true
    },
    {
      name:'الرابط',
      cell:(row)=>(
        <a href={`/registerstore?refCode=${row.code}`} target="_blank">
        {`${baseUrl}/registerstore?refCode=${row.code}`}
      </a>
      )
    },
    {
      name: t("the status"),
      cell: (row) =>
        row.status == true ? (
          <Button
            variant="danger"
            onClick={()=>editCode(row._id,false)}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={()=>editCode(row._id,true)}
            id={row._id}
          >
            {t("active")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

  ];

  const tableData = {
    columns,
    data: codes
  }

  return (
    <div>
      <Helmet>
        <title>
          {`  اكواد التسويق - ${Cookies.get("i18next") === "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
            }`}{" "}
        </title>
      </Helmet>

      <Modal show={showModal} onHide={()=>setShowModal(false)} style={{width:'fit-content !important'}}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">اضافة كود </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="name" className="d-block my-2">اسم الموظف او الحملة</label>
          <input id="name" value={name} onChange={(e)=>setName(e.target.value)} className="form-control" />
          <label htmlFor="code" className="d-block my-2">الكود</label>
          <input id="code" value={code} onChange={(e)=>setCode(e.target.value)} className="form-control" />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="font"
            variant="secondary"
             onClick={()=>setShowModal(false)}
          >
            تراجع
          </Button>
          <Button className="font" variant="success" 
           onClick={addNewCode}
          >
            اضافة
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex justify-content-between my-2">
        <div className="admin-content-text">اكواد التسويق</div>
        <div>
          <button
          onClick={()=>setShowModal(true)} 
          className="btn btn-success mx-2" >
            اضافة
          </button>
        </div>
      </div>

      <div className=" justify-content-center">
        <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
          <DataTable
            noHeader
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>

        <ToastContainer />
    </div>
  );
}
