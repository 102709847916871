export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const GET_ERROR = "GET_ERROR";
export const GET_ALL_BRAND = "GET_ALL_BRAND";
export const CREATE_BRAND = "CREATE_BRAND";
export const CREATE_SUB_CATEGORY = "CREATE_SUB_CATEGORY";
export const GET_SUB_BY_CAT_ID = "GET_SUB_BY_CAT_ID";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_SIMILAR_PRODUCTS = "GET_SIMILAR_PRODUCTS";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const LOGIN_USER = "LOGIN_USER";
export const CREATE_REVIEW = "CREATE_REVIEW";
export const REVIEWS_BY_PRODUCT_ID = "REVIEWS_BY_PRODUCT_ID";
export const DELETE_REVIEW = "DELETE_REVIEW";
export const UPDATE_REVIEW = "UPDATE_REVIEW";
export const ADD_TO_FAV = "ADD_TO_FAV";
export const REMOVE_FROM_FAV = "REMOVE_FROM_FAV";
export const USER_FAVORITE = "USER_FAVORITE";
export const ADD_COUPON = "ADD_COUPON";
export const GET_ALL_COUPON = "GET_ALL_COUPON";
export const DELETE_COUPON = "DELETE_COUPON";
export const GET_ONE_COUPON = "GET_ONE_COUPON";
export const EDIT_COUPON = "EDIT_COUPON";
export const USER_ADD_ADDRESS = "USER_ADD_ADDRESS";
export const GET_USER_ADDRESS_BY_ID = "GET_USER_ADDRESS_BY_ID";
export const DELETE_USER_ADDRESS = "DELETE_USER_ADDRESS";
export const USER_EDIT_ADDRESS = "USER_EDIT_ADDRESS";
export const GET_ONE_ADDRESS = "GET_ONE_ADDRESS";
export const ALL_USERS = "ALL_USERS";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const ADD_TO_CART = "ADD_TO_CART";
export const GET_USER_CART_ITEMS = "GET_USER_CART_ITEMS";
export const DELETE_ALL_CART_ITEMS = "DELETE_ALL_CART_ITEMS";
export const REMOVE_ONE_ITEM = "REMOVE_ONE_ITEM";
export const UPDATE_ITEM_CART = "UPDATE_ITEM_CART";
export const APPLY_COUPON = "APPLY_COUPON";
export const CREATE_ORDER = "CREATE_ORDER";
export const GET_ALL_PAYMENT_METHOD = "GET_ALL_PAYMENT_METHOD";
export const GET_ALL_ORDERS_BY_USER = "GET_ALL_ORDERS_BY_USER";
export const GET_ALL_ORDERS_BY_ADMIN = "GET_ALL_ORDERS_BY_ADMIN";
export const GET_ONE_ORDER = "GET_ONE_ORDER";
export const UPDATA_ORDER_STATUS = "UPDATA_ORDER_STATUS";
export const CREATE_NEW_SLIDER = "CREATE_NEW_SLIDER";
export const GET_ALL_SLIDER = "GET_ALL_SLIDER";
export const GET_ONE_USER = "GET_ONE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_ALL_SHIPPING_METHODS = "GET_ALL_SHIPPING_METHODS";
export const EDIT_SETTINGS = "EDIT_SETTINGS";
export const GET_POLICY_TERMS_ABOUT = "GET_POLICY_TERMS_ABOUT";
export const UPDATE_TERMS_ABOUT_POLICY = "UPDATE_TERMS_ABOUT_POLICY";
export const GET_ALL_ORDER_STATUS = "GET_ALL_ORDER_STATUS";
export const ADMIN_ALL_CARTS = "ADMIN_ALL_CARTS";
export const SEND_EMAIL = "SEND_EMAIL";
export const GET_ALL_PRODUCT_CATEGORY = "GET_ALL_PRODUCT_CATEGORY";
export const GET_ALL_PRODUCT_SUB_CATEGORY = "GET_ALL_PRODUCT_SUB_CATEGORY";
export const GET_ALL_PRODUCT_BRAND = "GET_ALL_PRODUCT_BRAND";
export const BRAND_BY_ID = "BRAND_BY_ID";
export const GET_CAT_BY_ID = "GET_CAT_BY_ID";
export const GET_SALES_REPORT = "GET_SALES_REPORT";
export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const GET_ONE_PAYMENT_METHOD = "GET_ONE_PAYMENT_METHOD";
export const EDIT_PAYMENT_METHOD = "EDIT_PAYMENT_METHOD";
export const ADD_VENDOR = "ADD_VENDOR";
export const GET_PRODUCTS_BY_VENDOR_ID = "GET_PRODUCTS_BY_VENDOR_ID";
export const GET_ORDERS_BY_VENDOR_ID = "GET_ORDERS_BY_VENDOR_ID";
export const ADD_USER_BY_ADMIN = "ADD_USER_BY_ADMIN";
export const GET_WEBSITE_DATA = "GET_WEBSITE_DATA";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_SLIDER = "DELETE_SLIDER";
export const UPDATE_SLIDER = "UPDATE_SLIDER";
export const GET_ONE_SLIDER = "GET_ONE_SLIDER";
export const GET_ONE_CATEGORY = "GET_ONE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_BRAND = "UPDATE_BRAND";
export const PRINT_INVOICE_ORDER = "PRINT_INVOICE_ORDER";
export const ADD_SHIPPING_METHODS = "ADD_SHIPPING_METHODS";
export const GET_ONE_SHIPPING_METHODS = "GET_ONE_SHIPPING_METHODS";
export const EDIT_SHIPPING_METHODS = "EDIT_SHIPPING_METHODS";
export const UPDATE_PRODUCT_STATUS = "UPDATE_PRODUCT_STATUS";
export const CREATE_NEW_ADS = "CREATE_NEW_ADS";
export const GET_ALL_ADS = "GET_ALL_ADS";
export const DELETE_ADS = "DELETE_ADS";
export const UPDATE_ADS = "UPDATE_ADS";
export const GET_ONE_ADS = "GET_ONE_ADS";
export const GET_ALL_ADS_WEB_SITE = "GET_ALL_ADS_WEB_SITE";
export const GET_ONE_USER_TOKEN = "GET_ONE_USER_TOKEN";
export const GET_VENDOR_BY_USERNAME = "GET_VENDOR_BY_USERNAME";
export const ADD_TICKET = "ADD_TICKET";
export const GET_ALL_TICKETS = "GET_ALL_TICKET";
export const UPDATE_TICKET = "UPDATE_TICKET";
export const ADD_ORDER_HISTORY = "ADD_ORDER_HISTORY";
export const GET_ALL_ORDER_HISTORY = "GET_ALL_ORDER_HISTORY";
export const GET_ORDERS_BY_DATE = "GET_ORDERS_BY_DATE";
export const GET_ALL_CATEGORIES_WITHOUT_BLOCHED_GATEGORY =
  "GET_ALL_CATEGORIES_WITHOUT_BLOCHED_GATEGORY";
export const GET_ALL_PRODUCT_ADMIN = "GET_ALL_PRODUCT_ADMIN";
export const PRINT_INVOICE_SHIPPMENTS = "PRINT_INVOICE_SHIPPMENTS";
export const CREATE_SHIPPMENTS = "CREATE_SHIPPMENTS";
export const GET_ONE_SHIPPING_METHODS_ID = "GET_ONE_SHIPPING_METHODS_ID";
export const GET_ALL_BRANDS = "GET_ALL_BRANDS";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const USER_TRANSACTION = "USER_TRANSACTION";
export const GET_ONE_TRANSACTION = "GET_ONE_TRANSACTION";
export const UPDATE_ONE_TRANSACTION = "UPDATE_ONE_TRANSACTION";
export const REFUNDORDER = "REFUNDORDER";
export const FORGET_PASSWORD_EMAIL_CODE = "FORGET_PASSWORD_EMAIL_CODE";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const ALL_OFFERS = "ALL_OFFERS";
export const ADD_OFFER = "ADD_OFFER";
export const GET_ONE_OFFER = "GET_ONE_OFFER";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const GET_OFFER_DETAILES_BY_OFFER_ID = "GET_OFFER_DETAILES_BY_OFFER_ID";
export const PAYMOB = "PAYMOB";
export const DELETE_PRODUCT_OFFER_BY_OFFER_DETAIL_ID =
  "DELETE_PRODUCT_OFFER_BY_OFFER_DETAIL_ID";
export const UPLOADEXCELFILE = "UPLOADEXCELFILE";
export const UPLOADIMAGEFROMEXCEL = "UPLOADIMAGEFROMEXCEL";
export const GET_ALL_PRODUCTS_HAS_NO_OFFER_AND_FILTER =
  "GET_ALL_PRODUCTS_HAS_NO_OFFER_AND_FILTER";
export const ALL_PRODUCTS_FILTER_FOR_ADD_TO_OFFER =
  "ALL_PRODUCTS_FILTER_FOR_ADD_TO_OFFER";
export const ADD_PRODUCT_TO_OFFER = "ADD_PRODUCT_TO_OFFER";
export const GET_ALL_OFFERS = "GET_ALL_OFFERS";
export const GET_ALL_REVIEWS_OF_PRODUCT = "GET_ALL_REVIEWS_OF_PRODUCT";
export const BACKUP = "BACKUP";
export const DOWNLOAD_BACKUP = "DOWNLOAD_BACKUP";
export const ALL_BACKUP_LOGS = "ALL_BACKUP_LOGS";
export const DELETE_BACKUP_LOG = "DELETE_BACKUP_LOG";
export const RESTORE_BACKUP_LOG = "RESTORE_BACKUP_LOG";
export const RESTORE_BACKUP_FILE = "RESTORE_BACKUP_FILE";
export const ALL_VENDORS = "ALL_VENDORS";
export const PRODUCTS_FEATURED_LIMIT = "PRODUCTS_FEATURED_LIMIT";
export const GET_ALL_DROPSHIPPINGS = "GET_ALL_DROPSHIPPINGS";
export const UPDATE_DROPSHIPPING = "UPDATE_DROPSHIPPING";
export const GET_ONE_DROP_SHIPPING = "GET_ONE_DROP_SHIPPING";

export const GET_CATEGORIES_FROM_DROP_SHIPPING =
  "GET_CATEGORIES_FROM_DROP_SHIPPING";
export const GET_PRODUCTS_FOR_ONE_CATEGORY_FROM_DROP_SHIPPING =
  "GET_PRODUCTS_FOR_ONE_CATEGORY_FROM_DROP_SHIPPING";
export const GET_ALL_STORES = "GET_ALL_STORES";
export const GET_ONE_STORE_BY_NAME = "GET_ONE_STORE_BY_NAME";
export const SELECT_STORE_BY_SUPER_ADMIN = "SELECT_STORE_BY_SUPER_ADMIN";

export const ALL_SUBSCRIPTIONS = "ALL_SUBSCRIPTIONS";
export const CREATE_NEW_SUBSCRIPTION = "CREATE_NEW_SUBSCRIPTION";
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const PAYMENT_SUBSCRIPTION = "PAYMENT_SUBSCRIPTION";
export const LAST_SUBSCRIPTION = "LAST_SUBSCRIPTION";
export const SUPER_ADMIN_LOGIN = "SUPER_ADMIN_LOGIN";
export const CREATE_NEW_STORE = "CREATE_NEW_STORE";
export const ONE_STORE = "ONE_STORE";
export const LAST_SUBSCRIPTION_LOADING_START="LAST_SUBSCRIPTION_LOADING_START";
export const GET_LAST_SUBSCRIPTION_BY_ADMIN="GET_LAST_SUBSCRIPTION_BY_ADMIN";
export const SHOWSlELECTSTORECOMPONENT="SHOWSlELECTSTORECOMPONENT";
export const UPDATE_STORE="UPDATE_STORE";
export const iS_UPDATING="iS_UPDATING";
export const UPDATE_STORENAME="UPDATE_STORENAME";
export const GET_ALL_REF_CODES="GET_ALL_REF_CODES";
export const CREATE_REF_CODE="CREATE_REF_CODE";
export const UPDATE_RED_CODE="UPDATE_RED_CODE";