import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import SideBar from "../../Components/Admin/SideBar";
import AdminAddSubscription from "../../Components/Admin/AdminAddSubscription";

const AdminAddSupscriptionPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
        <AdminAddSubscription />
      </div>
    </div>
  );
};

export default AdminAddSupscriptionPage;
