import React, { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import avatar from "../../Images/avatar.png";
import { ToastContainer } from "react-toastify";
import add from "../../Images/add.png";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { CompactPicker } from "react-color";
import AdminAddSliderHook from "../../hook/admin/AdminAddSliderHook";
import { Helmet } from "react-helmet";
import MultiImageInput from "react-multiple-image-input";
import AdminAddSubscriptionHook from "../../hook/admin/AdminAddSubscriptionHook";
import formatDate2 from "../Utilities/formDate2";

const AdminAddSubscription = () => {
  const { t } = useTranslation();
  const [
    monthlySubscriptionPeriod,
    price,
    check,
    isPaid,
    onChangeMonthlySubscriptionPeriod,
    onChangePrice,
    onChangeIsPaid,
    handleSubmit,
    settingsRes,
    user,
    allStoresResponse,
    changeStore,
    lastSubscription,
    storeIdResponse
  ] = AdminAddSubscriptionHook();

  // console.log("lastSubscription");
  // console.log(lastSubscription);

  return (
    <div>
      <Helmet>
        <title>
          {`  ${t("Subscriptions")} - ${
            Cookies.get("i18next") === "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>
      <Row className="justify-content-start ">
        {user.role === "admin" && (
          <div className="admin-content-text text-center">
            {" "}
            {t("The current subscription expires:")}
            {formatDate2(lastSubscription?.endDateSubscription)}
          </div>
        )}

        <div className="admin-content-text pb-4">{t("Add Subscription")}</div>
        <Col sm="8">
          <label htmlFor="link" className="d-block mt-2 ">
            {" "}
            {t("Monthly Subscription Period")}{" "}
          </label>
          <input
            id="monthlyPeriod"
            value={monthlySubscriptionPeriod}
            onChange={onChangeMonthlySubscriptionPeriod}
            type="number"
            className="input-form d-block mt-2 px-3"
            placeholder={t("Monthly Subscription Period")}
          />

          <label htmlFor="Price" className="d-block mt-2 ">
            {" "}
            {t("Price")}
          </label>
          <input
            id="Price"
            disabled
            value={price}
            onChange={onChangePrice}
            type="number"
            className="input-form d-block mt-2 px-3"
            placeholder={t("Price")}
          />
          {user?.role === "superAdmin" && (
            <>
              <label htmlFor="payment" className="d-block mt-2 ">
                {" "}
                {t("payment Done")}{" "}
              </label>

              <input
                id="payment"
                checked={isPaid}
                onChange={onChangeIsPaid}
                type="checkbox"
                className="form-check form-check-input d-block mt-2 "
                placeholder={t("payment Done")}
              />
            </>
          )}

          {user?.role === "superAdmin" ? (
            <>
              <label htmlFor="type" className="d-block mt-2 ">
                {" "}
                {t("select store")}{" "}
              </label>
              <select
                id="select-store"
                name="select-store"
                className="select input-form-area  mt-3 px-2"
                value={storeIdResponse._id}
                // onChange={changeStore}
              >
                <option>{storeIdResponse?.name}</option>
                {/* <option value="">{t("select store")}</option>
                {allStoresResponse
                  .filter((el) => el._id !== user?.storeId)
                  .map((store) => (
                    <option className="fw-bold text-black" value={store._id}>
                      {store.name}
                    </option>
                  ))} */}
              </select>
            </>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          {check === true ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <button
              onClick={handleSubmit}
              className="btn-save d-inline mt-2 "
              style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            >
              {t("save")}
            </button>
          )}
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminAddSubscription;
