import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminEditUserHook from "../../hook/admin/AdminEditUserHook";
import { useSelector } from "react-redux";

const AdminEditUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [
    firstname,
    lastname,
    mobile,
    email,
    status,
    onChangeFirstname,
    onChangeLastname,
    onChangeEmail,
    onChangeStatus,
    onChangeMobile,
    onSubmit,
    userType,
    onChangeuserType,
    webSiteData,
    userName,
    onChangeUsername,
    usernameExists,
    checkLoading,
    settingsRes,
  ] = AdminEditUserHook(id);

  const storeNameResponse = useSelector((state) => state.allStores.oneStore);

  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">تعديل المستخدم</div>
        <Col sm="8">
          <label htmlFor="firstName" className="d-block mt-3">
            {" "}
            الاسم الاول{" "}
          </label>
          <input
            id="firstName"
            value={firstname}
            onChange={onChangeFirstname}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder="الاسم الاول"
          />
          <label htmlFor="secondName" className="d-block mt-3">
            {" "}
            الاسم الثاني{" "}
          </label>
          <input
            id="secondName"
            value={lastname}
            onChange={onChangeLastname}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder="الاسم الاخير"
          />
          <label htmlFor="username" className="d-block mt-3">
            {" "}
            نوع المسخدم{" "}
          </label>

          <select
            value={userType}
            onChange={onChangeuserType}
            name="userType"
            id="userType"
            className="select input-form-area mt-2 px-2 "
          >
            <option value="0">نوع المستخدم</option>
            <option value="admin"> أدمن</option>
            <option value="user">مستخدم</option>
            {storeNameResponse?.MULTI_VENDOR == true ? (
              <option value="vendor">تاجر</option>
            ) : null}
          </select>

          {userType == "vendor" ? (
            <>
              <label htmlFor="username" className="d-block mt-2 ">
                {" "}
                {t("username")}{" "}
              </label>
              <div className="d-flex justify-content-center align-items-center">
                <input
                  id="username"
                  value={userName}
                  onChange={onChangeUsername}
                  type="text"
                  className="input-form d-block mt-2 px-3"
                  placeholder={t("username")}
                />
                {checkLoading === true ? <Spinner animation="border" /> : null}
              </div>
              {userName != "" ? (
                usernameExists == true ? (
                  <p style={{ color: "red" }}>
                    {" "}
                    {"هذا المستخدم موجود بالفعل"}{" "}
                  </p>
                ) : (
                  <p style={{ color: "green" }}> {"هذا الاسم متاح"} </p>
                )
              ) : null}
            </>
          ) : null}

          <label htmlFor="phone" className="d-block mt-3">
            {" "}
            رقم الجوال{" "}
          </label>
          <input
            id="phone"
            value={mobile}
            onChange={onChangeMobile}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder="رقم الجوال"
          />
          <label htmlFor="email" className="d-block mt-3">
            {" "}
            البريد الالكتروني{" "}
          </label>
          <input
            id="email"
            value={email}
            onChange={onChangeEmail}
            type="email"
            className="input-form d-block mt-2 px-3"
            placeholder="البريد الالكتروني"
          />
          <label htmlFor="status" className="d-block mt-3">
            {" "}
            حالة المستخدم{" "}
          </label>
          <select
            value={status}
            onChange={onChangeStatus}
            name="status"
            id="status"
            className="select input-form-area mt-2 px-2 "
          >
            <option value="0">حالة المستخدم</option>
            <option value="true">نشط</option>
            <option value="false">غير نشط</option>
          </select>
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            حفظ التعديلات
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminEditUser;
