import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllOffers,
  updateOffer,
  addOffer,
} from "../../redux/actions/offersAction";
import notify from "../useNotification";
import getStoreNameFromUrl from "../../Components/Utilities/getStoreNameFromUrl";

const storeName = getStoreNameFromUrl();

const AllOffersHook = () => {
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLaoding] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(true);
  const [showAddComponnet, setShowAddComponent] = useState(true);
  const [showSelectStoreComponent, setShowSelectStoreComponent] =
    useState(false);
  const [offers, setOffers] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");

  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }

  const onChangeNameAr = (e) => {
    setNameAr(e.target.value);
  };

  const onChangeNameEn = (e) => {
    setNameEn(e.target.value);
  };

  const onChangeStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const onChangeEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  //get store response
  const storeIdRes = useSelector(
    (state) => state.allStores.selectStoreBySuperAdmin
  );

  //on change store
  const changeStore = (e) => {
    console.log("e.target.value");
    console.log(e.target.value);
    setSelectedStore(e.target.value);
  };

  //all store response
  const allStoresResponse = useSelector((state) => state.allStores.allStores);

  useEffect(() => {
    if (user?.role === "vendor") {
      dispatch(getAllOffers(user._id, user?.storeId));
    } else {
      if (user.role === "superAdmin") {
        setShowSelectStoreComponent(true);
        if (storeIdRes !== "") {
          dispatch(getAllOffers("", storeIdRes));
          setShowAddComponent(true);
        } else {
          setShowAddComponent(false);
          setOffers([]);
        }
      } else dispatch(getAllOffers("", user?.storeId));
    }
  }, [dispatch, storeIdRes, user._id, user.role, user?.storeId]);

  const AllOffers = useSelector((state) => state.offersReducers.allOffers);

  useEffect(() => {
    if (AllOffers) {
      // console.log("AllOffers");
      // console.log(AllOffers);
      setOffers(AllOffers);
    }
  }, [AllOffers]);

  //update the status Of Offer
  const handleShowFun = async (id, status) => {
    setLoadingUpdate(true);
    await dispatch(
      updateOffer(id, {
        status: status,
      })
    );
    setLoadingUpdate(false);
  };

  const updateOfferStatusRes = useSelector(
    (state) => state.offersReducers.updateOffer
  );

  useEffect(() => {
    if (loadingUpdate === false) {
      if (updateOfferStatusRes) {
        notify(t("Modified successfully"), "success");
        setTimeout(() => {
          if (user.role === "superAdmin") {
            setShowSelectStoreComponent(true);
            if (storeIdRes !== "") {
              dispatch(getAllOffers("", storeIdRes));
              setShowAddComponent(true);
            } else {
              setShowAddComponent(false);
              setOffers([]);
            }
          } else dispatch(getAllOffers("", user?.storeId));
        }, 1500);
      }
    }
  }, [dispatch, loadingUpdate, t, updateOfferStatusRes]);

  //get settings
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  // add Offer
  const handleSubmit = async () => {
    if (nameAr === "" || nameEn === "" || startDate === "" || endDate === "") {
      notify(t("please complete the data"), "warn");
      return;
    }
    if (user?.role === "superAdmin" && selectedStore === "") {
      notify("من فضلك تأكد من اختيار المتجر", "error");
      return;
    } else {
      setLaoding(true);
      await dispatch(
        addOffer({
          nameAr: nameAr,
          nameEn: nameEn,
          startDate: startDate,
          endDate: endDate,
          user: user._id,
          storeId: user?.role === "superAdmin" ? selectedStore : user?.storeId,
        })
      );
      setLaoding(false);
    }
  };

  const addOfferResponse = useSelector(
    (state) => state.offersReducers.addOffer
  );

  useEffect(() => {
    if (loading === false) {
      if (addOfferResponse) {
        notify(t("Added successfully"), "success");
        setTimeout(() => {
          if (user?.role === "vendor") {
            navigate(`/vendor/offers`);
          } else {
            navigate(`/admin/offers`);
          }
        }, 1500);
      } else {
        notify(t("Failed in the Adding process"), "error");
      }
    }
  }, [loading, addOfferResponse, t, navigate, user?.role]);

  // navigate to Edit Offer Page
  const clickHandler = (id) => {
    if (user?.role === "vendor") {
      navigate(`/vendor/editOffer/${id}`);
    } else {
      navigate(`/admin/editOffer/${id}`);
    }
  };

  return [
    offers,
    handleShowFun,
    settingsRes,
    nameAr,
    nameEn,
    startDate,
    endDate,
    onChangeNameAr,
    onChangeNameEn,
    onChangeStartDate,
    onChangeEndDate,
    handleSubmit,
    clickHandler,
    user,
    showAddComponnet,
    showSelectStoreComponent,
    changeStore,
    allStoresResponse,
  ];
};

export default AllOffersHook;
