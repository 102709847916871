import React, { useEffect, useState } from "react";
import { createBrand } from "../../redux/actions/brandAction";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import avatar from "../../Images/avatar.png";
import { getSettings } from "../../redux/actions/settingsAcions";

const AddBrandHook = () => {
  const dispatch = useDispatch();
  const [img, setImg] = useState(avatar);
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPress, setIsPress] = useState(false);

  const [selectedStore, setSelectedStore] = useState("");

  //on change store
  const changeStore = (e) => {
    console.log("e.target.value");
    console.log(e.target.value);
    setSelectedStore(e.target.value);
  };

  //all store response
  const allStoresResponse = useSelector((state) => state.allStores.allStores);

  const user = JSON.parse(localStorage.getItem("user"));

  const res = useSelector((state) => state.allBrand.brand);
  const onChangeNameAr = (e) => {
    setNameAr(e.target.value);
  };
  const onChangeNameEn = (e) => {
    setNameEn(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImg(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };
  const handleSubmit = async (event) => {
    if (nameAr === "" || nameEn === "" || selectedFile === null) {
      notify("من فضلك اكمل البيانات", "warn");
      return;
    }
    if (user?.role === "superAdmin" && selectedStore === "") {
      notify("من فضلك تأكد من اختيار المتجر", "error");
      return;
    }

    event.preventDefault();
    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("nameEn", nameEn);
    formData.append("logo", selectedFile);
    formData.append(
      "storeId",
      user?.role === "superAdmin" ? selectedStore : user?.storeId
    );

    setIsPress(true);
    await dispatch(createBrand(formData));
    setLoading(false);
  };

  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  useEffect(() => {
    if (loading === false) {
      setImg(avatar);
      setNameAr("");
      setNameEn("");
      setSelectedFile(null);
      setLoading(true);
      setTimeout(() => setIsPress(false), 1000);

      if (res.status === 201) {
        notify("تم الاضافة بنجاح", "success");
      } else {
        notify("فشل في عملية الاضافه", "error");
      }
    }
  }, [loading]);
  return [
    img,
    nameAr,
    nameEn,
    isPress,
    loading,
    handleSubmit,
    onImageChange,
    onChangeNameAr,
    onChangeNameEn,
    settingsRes,
    changeStore,
    allStoresResponse,
    user,
  ];
};

export default AddBrandHook;
