import React, { useEffect, useState } from "react";
import {
  addCoupon,
  eidtCoupon,
  getAllCoupon,
  getOneCoupon,
} from "../../redux/actions/couponAction";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";
import getStoreNameFromUrl from "../../Components/Utilities/getStoreNameFromUrl";

const storeName=getStoreNameFromUrl();

const EditCouponHook = (id) => {
  const [couponName, setCouponName] = useState("");
  const [couponDate, setCouponDate] = useState("");
  const [couponValue, setCouponValue] = useState("");
  const [couponType, setCouponType] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const oneCoupon = useSelector((state) => state.couponReducer.oneCoupon);

  useEffect(() => {
    const get = async () => {
      setLoadingData(true);
      await dispatch(getOneCoupon(id));
      setLoadingData(false);
    };
    get();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  useEffect(() => {
    if (oneCoupon) {
      setCouponName(oneCoupon.title);
      setCouponDate(formatDate(oneCoupon.endDate));
      setCouponValue(oneCoupon.value);
      setCouponType(oneCoupon.type);
    }
  }, [loadingData]);

  const onChangeCouponName = (e) => {
    e.persist();
    setCouponName(e.target.value);
  };
  const onChangeCouponDate = (e) => {
    e.persist();
    setCouponDate(e.target.value);
  };
  const onChangeCouponValue = (e) => {
    e.persist();
    setCouponValue(e.target.value);
  };
  const onChangeCouponType = (e) => {
    e.persist();
    setCouponType(e.target.value);
  };
  const onSubmit = async () => {
    if (
      couponName === "" ||
      !couponType ||
      couponValue <= 0 ||
      couponDate === ""
    ) {
      notify("من فضلك اكمل البيانات", "error");
    }
    setLoading(true);
    await dispatch(
      eidtCoupon(id, {
        title: couponName.toUpperCase(),
        endDate: couponDate,
        type: couponType,
        value: couponValue,
      })
    );
    setLoading(false);
  };
  const res = useSelector((state) => state.couponReducer.editCoupon);

  useEffect(() => {
    if (loading === false) {
      if (res && res.success === true) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          navigate(`/admin/addcoupon`);
        }, 1000);
      } else {
        notify("فشل في عملية الاضافه", "error");
      }
    }
  }, [loading]);

  return [
    couponName,
    couponDate,
    couponValue,
    couponType,
    onChangeCouponName,
    onChangeCouponDate,
    onChangeCouponValue,
    onChangeCouponType,
    onSubmit,
  ];
};
export default EditCouponHook;
