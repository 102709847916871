import React, { useEffect, useState } from "react";
import { createBrand } from "../../redux/actions/brandAction";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import avatar from "../../Images/avatar.png";
import CryptoJS from "crypto-js";

import {
  createNewSubscription,
  getLastSubscription,
  paymentSunscription,
  getLastSubscriptionForAdmin
} from "../../redux/actions/subscriptionAction";
import { getSettings } from "../../redux/actions/settingsAcions";
import { useTranslation } from "react-i18next";
import axios from "axios";
import config from "../../Urway/Configuration.json";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getStoreById } from "../../redux/actions/storesActions";

const generateHashSHA256 = (hashSequence) => {
  // hashSequence = trackid | terminalId | password | secret | amount | currency
  let hash = CryptoJS.SHA256(hashSequence).toString();
  return hash;
};

const AdminAddSubscriptionHook = () => {

  // const { selectedStore : selectedStorId } = useParams();
 const[searchParams, setSearchParams] =useSearchParams();

 const selectedStorId=searchParams.get('storeIdRes');
  console.log('selected store');
  console.log(selectedStorId);

  let resParameter = {};
  let index = 0;
  let count = 0;
  let queryParam = "";

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [monthlySubscriptionPeriod, setMonthlySubscriptionPeriod] = useState(1);
  const [check, setCheck] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [double, setDouble] = useState(false);

  const [selectedStore, setSelectedStore] = useState("");

  const [loading, setLoading] = useState(true);

  const user = JSON.parse(localStorage.getItem("user"));

  const onChangeMonthlySubscriptionPeriod = (e) => {
    setMonthlySubscriptionPeriod(e.target.value);
    if(user?.role==="superAdmin") setPrice(e.target.value * storeIdResponse?.priceSubscription);

    else setPrice(e.target.value * settingsRes.data?.PRICE_SUBSCRIPTION_FOR_MONTH);
  };

  

  //get last subscription
  useEffect(() => {
    // console.log("user");
    // console.log(user);
    if(selectedStorId) dispatch(getStoreById(selectedStorId));
    dispatch(getLastSubscriptionForAdmin(user.storeId));
  }, []);
  const lastSubscription = useSelector(
    (state) => state.subscriptionReducer.lastSubscriptionForAdmin
  );

  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const storeIdResponse = useSelector((state) => state.allStores.oneStoreById);

  console.log('storeIdResponse');
 console.log(storeIdResponse);

  const navigate = useNavigate();

  const [price, setPrice] = useState(0);

  const onChangePrice = (e) => {
    setPrice(e.target.value);
  };

  const onChangeIsPaid = (e) => {
    // console.log("e.target");
    // console.log(e.target.checked);
    setIsPaid(e.target.checked);
  };

  useEffect(() => {
    if (settingsRes&&user.role==="admin") {
      setPrice(settingsRes.data?.PRICE_SUBSCRIPTION_FOR_MONTH);
    }
  }, [settingsRes]);

  useEffect(()=>{
    if(storeIdResponse&&user.role==="superAdmin") {
      setSelectedStore(storeIdResponse._id);
      setPrice(storeIdResponse?.priceSubscription);
    }
  },[storeIdResponse]);

  //on change store
  const changeStore = (e) => {
    // console.log("e.target.value");
    // console.log(e.target.value);
    setSelectedStore(e.target.value);
  };

  //all store response
  const allStoresResponse = useSelector((state) => state.allStores.allStores);

  // console.log('allStoresResponse');
  // console.log(allStoresResponse);


  const handleSubmit = async (event) => {

    // console.log('monthlySubscriptionPeriod',monthlySubscriptionPeriod);
    // return;

    if (Math.sign(monthlySubscriptionPeriod) === -1) {
      notify(t("Enter a monthly subscription period"), "warn");
      return;
    }

    if (user?.role === "superAdmin" && selectedStore === "") {
      notify("من فضلك تأكد من اختيار المتجر", "error");
      return;
    }

    event.preventDefault();

    if (user?.role === "superAdmin") {
      setCheck(true);
      setLoading(true);
      await dispatch(
        createNewSubscription({
          store: selectedStore,
          user: user._id,
          period: monthlySubscriptionPeriod,
          isPaymentForAdmin: isPaid,
          status: isPaid === true ? true : false,
          currentDate:Date.now()
        })
      );
      setLoading(false);
      setCheck(false);
    } else {
      console.log("adminnnnnnnnnnnnnnnnnnnnnnn");

      setCheck(true);
      setLoading(true);
      localStorage.setItem("isPay", false);
      await dispatch(
        paymentSunscription({
          amount: price,
          firstname: user.firstname,
          lastname: user.lastname,
          mobile: user.mobile,
          email: user.email,
          period: monthlySubscriptionPeriod,
          store: user.storeId,
        })
      );
      setLoading(false);
      setCheck(false);
    }
  };
  const res = useSelector((state) => state.subscriptionReducer.newSubscription);
  const paymentRes = useSelector(
    (state) => state.subscriptionReducer.paymentSubscription
  );

  useEffect(() => {
    if (loading === false && user.role === "superAdmin") {
      setIsPaid(false);
      setMonthlySubscriptionPeriod(1);
      setPrice(0);
      console.log("res");
      console.log(res);
      if (res) {
        notify("تم الاضافة بنجاح", "success");
      } else {
        notify("فشل في عملية الاضافه", "error");
      }
    } else if (loading === false && user.role === "admin") {
      if (paymentRes) {
        console.log(paymentRes);
        console.log(paymentRes.url);

        window.location.href = paymentRes.url;
      }
    }
  }, [loading]);

  return [
    monthlySubscriptionPeriod,
    price,
    check,
    isPaid,
    onChangeMonthlySubscriptionPeriod,
    onChangePrice,
    onChangeIsPaid,
    handleSubmit,
    settingsRes,
    user,
    allStoresResponse,
    changeStore,
    lastSubscription,
    storeIdResponse
  ];
};
export default AdminAddSubscriptionHook;
