import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWebsiteData } from "../../redux/actions/settingsAcions";
import notify from "../../hook/useNotification";
import {
  getOneUser,
  getAllUsers,
  updateUser,
} from "../../redux/actions/usersActions";
import { getVendorByusername } from "../../redux/actions/vendorActions";

const AdminEditUserHook = (id) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [userType, setUserType] = useState("");
  const [userName, setUserName] = useState("");
  const [usernameExists, setUsernameExists] = useState();
  const [usernameLoading, setUsernameLoading] = useState(true);

  const [checkLoading, setcheckLoading] = useState(false);

  const dispatch = useDispatch();

  const onChangeuserType = (e) => {
    e.persist();
    setUserType(e.target.value);
  };
  const onChangeFirstname = (e) => {
    e.persist();
    setFirstname(e.target.value);
  };
  const onChangeLastname = (e) => {
    e.persist();
    setLastname(e.target.value);
  };
  const onChangeEmail = (e) => {
    e.persist();
    setEmail(e.target.value);
  };
  const onChangeStatus = (e) => {
    e.persist();
    setStatus(e.target.value);
  };
  const onChangeMobile = (e) => {
    e.persist();
    setMobile(e.target.value);
  };

  const onChangeUsername = async (e) => {
    e.persist();
    setUserName(e.target.value);
    setcheckLoading(true);
    setUsernameLoading(true);
    await dispatch(getVendorByusername(e.target.value));
    setUsernameLoading(false);
    setcheckLoading(false);
  };

  const vendorByusername = useSelector(
    (state) => state.vendorReducer.vendorByUsername
  );

  useEffect(() => {
    if (usernameLoading === false) {
      if (vendorByusername && vendorByusername?._id) {
        setUsernameExists(true);
      } else {
        setUsernameExists(false);
      }
    }
  }, [usernameLoading]);

  const oneUser = useSelector((state) => state.usersReducers.oneUser);

  useEffect(() => {
    const get = async () => {
      setLoadingData(true);
      await dispatch(getOneUser(id));
      setLoadingData(false);
    };
    get();
  }, []);
  const webSiteData = useSelector(
    (state) => state.settingsReducers.webSiteData
  );
  useEffect(() => {
    if (oneUser) {
      setFirstname(oneUser.firstname);
      setLastname(oneUser.lastname);
      setMobile(oneUser.mobile);
      setEmail(oneUser.email);
      setStatus(oneUser.status);
      setUserType(oneUser.role);
      setUserName(oneUser?.username);
    }
  }, [loadingData]);

  const res = useSelector((state) => state.usersReducers.updateUser);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const onSubmit = async () => {
    if (firstname === "") {
      notify("من فضلك ادخل الاسم الاول", "error");
      return;
    }
    if (lastname === "") {
      notify("من فضلك ادخل الاسم الاخير", "error");
      return;
    }
    if (email === "") {
      notify("من فضلك ادخل الايميل ", "error");
      return;
    }
    if (mobile === "") {
      notify("من فضلك ادخل رقم الجوال ", "error");
      return;
    }
    if (mobile.length < 9) {
      notify("من فضلك ادخل رقم الجوال صحيح", "error");
      return;
    }

    if (userType === "" || userType === "0") {
      notify("من فضلك تأكد من اختيار النوع", "error");
      return;
    }
    console.log("userName");
    console.log(userType);
    console.log(userName);
    if (userType === "vendor" && (!userName || userName === "")) {
      notify("من فضلك تأكد من  إضافة اسم مميز للتاجر", "error");
      return;
    }
    if (usernameExists == true) {
      notify("من فضلك تأكد من اختيار اسم المستخدم", "error");
      return;
    }
    setLoading(true);
    await dispatch(
      updateUser(id, {
        firstname: firstname,
        lastname: lastname,
        email: email,
        mobile: mobile,
        status: status,
        role: userType,
        username: userName,
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    if (loading === false) {
      if (res && res.success === true) {
        notify("تم التعديل بنجاح", "success");
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [
    firstname,
    lastname,
    mobile,
    email,
    status,
    onChangeFirstname,
    onChangeLastname,
    onChangeEmail,
    onChangeStatus,
    onChangeMobile,
    onSubmit,
    userType,
    onChangeuserType,
    webSiteData,
    userName,
    onChangeUsername,
    usernameExists,
    checkLoading,
    settingsRes,
  ];
};

export default AdminEditUserHook;
