import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeStatusOrder,
  createShipments,
  getAllOrderByAdmin,
  getAllOrderByUser,
  getOneOrder,
  getOrderStatus,
} from "../../redux/actions/orderAction";
import {
  addOrderHistory,
  getAllOrderHistory,
} from "../../redux/actions/orderHistoryAction";
import { addTransaction } from "../../redux/actions/walletAction";

import notify from "../useNotification";

const ChangeOrderStatusHook = (id, userId) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loadingD, setLoadingD] = useState(true);
  const [loadingRes, setLoadingRes] = useState(true);

  const [refundValue, setRefundValue] = useState("");
  const [refundDescription, setRefundDescription] = useState("");

  const [loadingCreateShippment, setLoadingCreateShippment] = useState(true);

  const [description, setDescription] = useState("");
  const [show, setShow] = useState(false);
  const [status, setState] = useState("");
  const handleClose = () => {
    if (status == 1) {
      changePayOrder();
    } else if (status == 2) {
      changeDeliveredOrder();
    }
    setShow(false);
  };

  const handleCloseModel = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const user = JSON.parse(localStorage.getItem("user"));

  const [pay, setPay] = useState();
  const [delivered, setDelivered] = useState();

  const onChangePaid = (e) => {
    setPay(e.target.value);
  };
  const onChangeDelivered = (e) => {
    setDelivered(e.target.value);
  };

  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const onChangeRefundValue = (e) => {
    setRefundValue(e.target.value);
  };
  const onChangeRefundDescription = (e) => {
    setRefundDescription(e.target.value);
  };

  const resOrderDetails = useSelector((state) => state.orderReducer.oneOrder);
  const allOrderHistory = useSelector(
    (state) => state.orderHistoryReducer.getAllOrderHistory
  );

  useEffect(() => {
    const get = async () => {
      setLoadingRes(true);
      await dispatch(getOrderStatus());
      await dispatch(getOneOrder(id));
      await dispatch(getAllOrderHistory(id));
      setLoadingRes(false);
    };
    get();
  }, []);

  useEffect(() => {
    if (loadingRes === false) {
      if (resOrderDetails && resOrderDetails.orderStatusId) {
        setDelivered(resOrderDetails.orderStatusId._id);
        setPay(resOrderDetails.isPaid);
      }
    }
  }, [loadingRes]);

  const orderStatusRes = useSelector((state) => state.orderReducer.orderStatus);

  const changeDeliveredOrder = async () => {
    if (!delivered) {
      notify("من فضلك ادخل حالة التوصيل", "warn");
      return;
    }

    const orderStatus = orderStatusRes
      ? orderStatusRes.data?.filter((el) => el._id == delivered)
      : null;
    const nameAr = orderStatus ? orderStatus[0].nameAr : null;

    setLoadingD(true);
    await dispatch(
      addOrderHistory({
        paymentStatus: pay,
        orderStatus: nameAr,
        desc: description,
        user: user._id,
        orderId: id,
      })
    );
    await dispatch(changeStatusOrder(id, { orderStatusId: delivered }));

    if (delivered == "63c7c73b643a9581a2d252ec") {
      await dispatch(
        addTransaction({
          price: refundValue,
          type: 1,
          user: userId,
          describtion: refundDescription,
          addedBy: user._id,
        })
      );
    }

    setLoadingD(false);
  };

  const changePayOrder = async () => {
    if (!pay) {
      notify("من فضلك ادخل حالة الدفع", "warn");
      return;
    }

    const orderStatus = orderStatusRes
      ? orderStatusRes.data?.filter((el) => el._id == delivered)
      : null;
    const nameAr = orderStatus ? orderStatus[0].nameAr : null;

    setLoading(true);

    await dispatch(
      addOrderHistory({
        paymentStatus: pay,
        orderStatus: nameAr,
        desc: description,
        user: user._id,
        orderId: id,
      })
    );
    await dispatch(changeStatusOrder(id, { isPaid: pay }));
    setLoading(false);
  };
  const showModalDescription = (t) => {
    setState(t);
    setShow(true);
  };

  const res = useSelector((state) => state.orderReducer.updateOrder);

  useEffect(() => {
    if (loading === false) {
      if (res && res.success === true) {
        notify("تم تغير حالة الدفع بنجاح", "success");
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 2000);
        dispatch(getAllOrderByAdmin(5));
      } else {
        notify("فشل في تغير حالة الدفع", "warn");
      }
    }
  }, [loading]);

  useEffect(() => {
    if (loadingD === false) {
      if (res && res.success === true) {
        notify("تم تغير حالة التوصيل بنجاح", "success");
        createShippment();
        dispatch(getAllOrderByAdmin(5));
      } else {
        notify("فشل في تغير حالة التوصيل", "warn");
      }
    }
  }, [loadingD]);

  const createShippment = async () => {
    setLoadingCreateShippment(true);
    await dispatch(
      createShipments({
        orderStatusId: delivered,
        orderId: id,
      })
    );
    setLoadingCreateShippment(false);
  };

  const createShippmentRes = useSelector(
    (state) => state.orderReducer.createShippment
  );

  useEffect(() => {
    if (loadingCreateShippment === false) {
      if (createShippmentRes) {
        // console.log(createShippmentRes)
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 2000);
      } else {
        notify("فشل في تغير حالة التوصيل", "warn");
      }
    }
  }, [loadingCreateShippment]);

  return [
    onChangePaid,
    changePayOrder,
    onChangeDelivered,
    changeDeliveredOrder,
    orderStatusRes,
    delivered,
    pay,
    show,
    handleClose,
    handleShow,
    description,
    onChangeDescription,
    showModalDescription,
    allOrderHistory,
    handleCloseModel,
    refundValue,
    onChangeRefundValue,
    onChangeRefundDescription,
    refundDescription,
  ];
};

export default ChangeOrderStatusHook;
