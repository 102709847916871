import {
  GET_ALL_BRAND,
  CREATE_BRAND,
  GET_ERROR,
  BRAND_BY_ID,
  UPDATE_BRAND,
  GET_ALL_BRANDS,
} from "../type";
import { useGetData } from "../../hooks/useGetData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";
import { useUpdateDataWithImage } from "../../hooks/useUpdateData";

export const getAllBrands = (storeId,admin) => async (dispatch) => {
  try {
    let url=`/api/v1/brands?storeId=${storeId}`;
    if(admin) url+='&admin=1';
    const response = await useGetData(url);

    dispatch({
      type: GET_ALL_BRANDS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BRANDS,
      payload: error,
    });
  }
};

export const getAllBrand = (limit, storeId) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/brands/website?limit=${limit}&storeId=${storeId}`
    );

    dispatch({
      type: GET_ALL_BRAND,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BRAND,
      payload: error,
    });
  }
};

export const getBrandById = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/brands/${id}`);

    dispatch({
      type: BRAND_BY_ID,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const getAllBrandPage = (page) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/brands/website?limit=12&page=${page}`
    );

    dispatch({
      type: GET_ALL_BRAND,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const createBrand = (formData) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage(`/api/v1/brands`, formData);

    dispatch({
      type: CREATE_BRAND,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const updateBrands = (id, formData) => async (dispatch) => {
  try {
    const response = await useUpdateDataWithImage(
      `/api/v1/brands/${id}`,
      formData
    );
    dispatch({
      type: UPDATE_BRAND,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_BRAND,
      payload: error.response,
    });
  }
};
