import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCategory,
  getAllCategoriesWithoutBlockedCategory,
} from "../../redux/actions/categoryAction";
import { getAllBrand } from "../../redux/actions/brandAction";
import { subCategoryByCatId } from "../../redux/actions/subCategoryAction";
import { getAllUsers } from "../../redux/actions/usersActions";
import { uploadExcelFile , uploadImagesFromExcel } from "../../redux/actions/excelActions";
import notify from "../../hook/useNotification";
import {
  getAllOrderByAdmin,
  getAllOrderByUser,
  getordersByCoupon,
  getordersByDate,
} from "../../redux/actions/orderAction";

import {
  getWebsiteData,
  getSettings,
} from "../../redux/actions/settingsAcions";
//import Base64JSON from "./readme.json";
//import { Buffer } from "buffer";

const AdminExcelHook = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  //console.log(user);
  const ref = useRef(null);

  const [categoryId, setCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [selectedsubId, setSelectedsubId] = useState("");
  const [brandId, setBrandId] = useState("");
  const [vedorId, setvedorId] = useState("");
  const [images, setImages] = useState([]);
  const [isPress, setIsPress] = useState(false);
  const [excel, setExcelFile] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingIMG, setLoadingIMG] = useState(true);
  


  useEffect(() => {
    dispatch(getAllCategory());
    dispatch(getAllCategoriesWithoutBlockedCategory());
    dispatch(getAllBrand());
    dispatch(getAllUsers());
  }, []);

  //to convert base 64 to file
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }


   function handleImageChange(event){
    //console.log('handleImageChange');
   // let target = event.target.files;
    setImages([...images, ...event.target.files]);

   } 

  

  const category = useSelector((state) => state.allCategory.category);
  //console.log(category)
  const categoryWithout = useSelector(
    (state) => state.allCategory.categoriesWithoutBlocked
  );

  const brand = useSelector((state) => state.allBrand.brand);
  //console.log(brand);
  const subCategoryByCat = useSelector(
    (state) => state.subCategory.subCategoryByCat
  );
  const getAllUser = useSelector((state) => state.usersReducers.allUser).filter(
    (el) => el.role === "vendor"
  );
  //console.log(getAllUser)
  const webSiteData = useSelector(
    (state) => state.settingsReducers.webSiteData
  );
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const onSelectCategory = async (e) => {
    if (e.target.value !== 0) {
      
      await dispatch(subCategoryByCatId(e.target.value));
    }
    setCategoryId(e.target.value);
  };
  const onSelectSubCategory = (e) => {
  
    setSelectedsubId(e.target.value);
  };

  const onSelectBrand = (e) => {
    setBrandId(e.target.value);
  };

  const onChangeVendor = (e) => {
    setvedorId(e.target.value);
  };


  const onChangeSetExcelFile = (event) => {
    //console.log(event.target.files[0]);
    if (event.target.files && event.target.files[0])
      //setExcelFile(URL.createObjectURL(event.target.files[0]));
      setExcelFile(event.target.files[0]);
  };

  useEffect(() => {
    if (user && user.role == "vendor") {
      setvedorId(user._id);
    }
  }, [user]);

  useEffect(() => {
    if (categoryId != 0) {
      if (subCategoryByCat) {
        setSubCategory(subCategoryByCat);
      }
    }
  }, [categoryId]);

  const handleUploadPhotos= async (e) =>{
    //uploadImagesFromExcel
    const formData = new FormData();
    images.map((item) => formData.append("images", item));

    setLoadingIMG(true);
      await dispatch(uploadImagesFromExcel(formData));
      setIsPress(false);
    setLoadingIMG(false);

  }

  const uploadImagesRes=useSelector((state) => state.excelReducer.uploadImagesFromExcel);

  const handleSubmit = async (e) => {
    setIsPress(true);
    e.preventDefault();

   if(user.role !=='admin' || user.role !=='superAdmin' )
   {
       setvedorId(user._id);
   } 


    if (
      categoryId === 0 ||
      categoryId == "" ||
      subCategory == "" ||
      subCategory == "0" ||
      selectedsubId == "" ||
      selectedsubId == "0" ||
      vedorId == "" ||
      vedorId == "0" ||
      excel==""
    ) {
      notify("من فضلك اكمل البيانات", "warn");
      setIsPress(false);
      return;
    }


    const formData = new FormData();
    formData.append("category", categoryId);
    formData.append("subCategory", selectedsubId);
    formData.append("brand", brandId);
    formData.append("vendor", vedorId);
    formData.append("excelFile", excel);
     
      setLoading(true);
      await dispatch(uploadExcelFile(formData));

      setIsPress(false);
      setLoading(false);
  };

  const uploadExcelRes = useSelector((state) => state.excelReducer.uploadExcelFile);
  //console.log(uploadExcelRes)

  useEffect(() => {

    //console.log(uploadExcelRes);
    if (loading === false) {
        setTimeout(() => setLoading(true), 1500);
        if (uploadExcelRes) {
            if (uploadExcelRes.status === 201) {
                notify("تم الاضافة بنجاح", "success");
                return;
              } else {
                notify("فشل في عملية الاضافه", "error");
                return;
              }
        }
    }
}, [loading]);

useEffect(()=>{

  if (loadingIMG === false) {

    if(uploadImagesRes)
  {
    if(uploadImagesRes)
    {
      notify("تم رفع الصور بنجاح", "success");
      return;
    }
    else {
      notify("فشل في عملية الرفع", "error");
      return;
    }
  }

  }
  

},[loadingIMG]);




  return [
    onSelectCategory,
    category,
    onSelectSubCategory,
    subCategory,
    onSelectBrand,
    brand,
    webSiteData,
    user,
    onChangeVendor,
    vedorId,
    getAllUser,
    images,
    setImages,
    settingsRes,
    isPress,
    onChangeSetExcelFile,
    handleImageChange,
    handleUploadPhotos,
    handleSubmit
   
  ];
};
export default AdminExcelHook;
