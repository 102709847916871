import React, { useEffect, useState } from "react";
import { createBrand } from "../../redux/actions/brandAction";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import avatar from "../../Images/avatar.png";
import { createNewSlider } from "../../redux/actions/sliderActions";
import { getSettings } from "../../redux/actions/settingsAcions";

const AdminAddSliderHook = () => {
  const dispatch = useDispatch();
  // const [img, setImg] = useState(avatar);
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedStore, setSelectedStore] = useState("");

  // const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showPicker, setShowPicker] = useState(false);
  const [colors, setColors] = useState("");
  // show color picker
  const [color, setColor] = useState("");

  const [link2, setLink2] = useState("");
  const [images, setImages] = useState([]);

  // show second color picker
  const [showSecondPicker, setShowSecondPicker] = useState(false);
  const [secondaryColor, setSecondaryColor] = useState("");
  const [page, setpage] = useState("home_screen");
  const [key, setkey] = useState(0);
  const [check, setCheck] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const onChangePicker = (e) => {
    setShowPicker(!showPicker);
  };
  const handleChaneComplete = (color) => {
    setColors(color.hex);
    setShowPicker(!showPicker);
  };

  //on change store
  const changeStore = (e) => {
    console.log("e.target.value");
    console.log(e.target.value);
    setSelectedStore(e.target.value);
  };

  const onChangeLink = (e) => {
    setLink(e.target.value);
  };

  const onChangeLink2 = (e) => {
    setLink2(e.target.value);
  };

  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };
  const onChangeKey = (e) => {
    setkey(e.target.value);
  };

  const handleChaneCompleteSecond = (color) => {
    setSecondaryColor(color.hex);
    setShowSecondPicker(!showSecondPicker);
  };

  // const onImageChange = (event) => {
  //   if (event.target.files && event.target.files[0])
  //     setImg(URL.createObjectURL(event.target.files[0]));
  //   setSelectedFile(event.target.files[0]);
  // };

  //all store response
  const allStoresResponse = useSelector((state) => state.allStores.allStores);

  //to convert base 64 to file
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const itemImages = Array.from(Array(Object.keys(images).length).keys()).map(
    (item, index) => {
      return dataURLtoFile(images[index], Math.random() + ".png");
    }
  );

  const handleSubmit = async (event) => {
    if (images === []) {
      notify("من فضلك اكمل البيانات", "warn");
      return;
    }
    if (user?.role === "superAdmin" && selectedStore === "") {
      notify("من فضلك تأكد من اختيار المتجر", "error");
      return;
    }
    event.preventDefault();
    const formData = new FormData();
    formData.append("link", link);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("color", colors);
    formData.append("link2", link2);
    formData.append(
      "storeId",
      user?.role === "superAdmin" ? selectedStore : user?.storeId
    );
    itemImages.map((item) => formData.append("images", item));

    setCheck(true);
    setLoading(true);
    await dispatch(createNewSlider(formData));
    setLoading(false);
    setCheck(false);
  };
  const res = useSelector((state) => state.sliderReducer.newSlider);

  useEffect(() => {
    if (loading === false) {
      // setImg(avatar);
      setLink("");
      setDescription("");
      setTitle("");
      setColors("");
      setImages([]);
      if (res.status === 201) {
        notify("تم الاضافة بنجاح", "success");
      } else {
        notify("فشل في عملية الاضافه", "error");
      }
    }
  }, [loading]);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  return [
    link,
    onChangeLink,
    title,
    onChangeTitle,
    description,
    onChangeDescription,
    handleSubmit,
    colors,
    onChangePicker,
    handleChaneComplete,
    showPicker,
    settingsRes,
    secondaryColor,
    page,
    key,
    onChangeKey,
    handleChaneCompleteSecond,
    link2,
    onChangeLink2,
    images,
    setImages,
    check,
    user,
    allStoresResponse,
    changeStore,
  ];
};
export default AdminAddSliderHook;
