import {
  GET_ALL_ORDERS_BY_USER,
  GET_ALL_ORDERS_BY_ADMIN,
  GET_ONE_ORDER,
  UPDATA_ORDER_STATUS,
  GET_ALL_ORDER_STATUS,
  PRINT_INVOICE_ORDER,
  GET_ORDERS_BY_DATE,
  PRINT_INVOICE_SHIPPMENTS,
  CREATE_SHIPPMENTS,
  REFUNDORDER,
} from "../type";

import { useGetData, useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { useInsertData } from "../../hooks/useInsertData";

export const getOrderStatus = () => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/orderStatus`);
    dispatch({
      type: GET_ALL_ORDER_STATUS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ORDER_STATUS,
      payload: error,
    });
  }
};
export const getordersByDate = (from, to) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/orders/get/orderByDate?from=${from}&to=${to}`
    );

    dispatch({
      type: GET_ORDERS_BY_DATE,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ORDERS_BY_DATE,
      payload: error,
    });
  }
};

export const getAllOrderByUser = (id, limit, page) => async (dispatch) => {
  try {
    const response = await useGetDataToken(
      `/api/v1/orders/user/${id}?limit=${limit}&page=${page}`
    );

    dispatch({
      type: GET_ALL_ORDERS_BY_USER,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ORDERS_BY_USER,
      payload: error,
    });
  }
};

export const getordersByCoupon = (coupon) => async (dispatch) => {
  try {
    const response = await useGetDataToken(
      `/api/v1/orders/get/copoun?coupon=${coupon}`
    );

    dispatch({
      type: GET_ALL_ORDERS_BY_ADMIN,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ORDERS_BY_ADMIN,
      payload: error,
    });
  }
};
// admin
export const getAllOrderByAdmin =
  (limit, page, storeId) => async (dispatch) => {
    try {
      const response = await useGetDataToken(
        `/api/v1/orders?limit=${limit}&page=${page}&storeId=${storeId}`
      );

      dispatch({
        type: GET_ALL_ORDERS_BY_ADMIN,
        payload: response,
        loading: true,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_ORDERS_BY_ADMIN,
        payload: error,
      });
    }
  };

export const getOneOrder = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/orders/${id}`);

    dispatch({
      type: GET_ONE_ORDER,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_ORDER,
      payload: error,
    });
  }
};

export const changeStatusOrder = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/orders/${id}`, body);

    dispatch({
      type: UPDATA_ORDER_STATUS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATA_ORDER_STATUS,
      payload: error,
    });
  }
};

//print invoice by order id
export const printInvoiceOrder = (orderId) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/orders/docs/${orderId}`);
    // console.log(response);
    dispatch({
      type: PRINT_INVOICE_ORDER,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: PRINT_INVOICE_ORDER,
      payload: error.response,
    });
  }
};
export const printInvoiceShippment = (orderId) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/orders/aramex/shippments/${orderId}`
    );
    // console.log(response);
    dispatch({
      type: PRINT_INVOICE_SHIPPMENTS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: PRINT_INVOICE_SHIPPMENTS,
      payload: error.response,
    });
  }
};

export const createShipments = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(
      `/api/v1/orders/aramex/createShipments`,
      body
    );
    // console.log(response);
    dispatch({
      type: CREATE_SHIPPMENTS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: CREATE_SHIPPMENTS,
      payload: error.response,
    });
  }
};

export const refundOrderItem = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(
      `/api/v1/orders/refundRequest/${id}`,
      body
    );
    // console.log(response);
    dispatch({
      type: REFUNDORDER,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: REFUNDORDER,
      payload: error.response,
    });
  }
};
