import { 
GET_ALL_REF_CODES,
CREATE_REF_CODE,
UPDATE_RED_CODE
 } from "../type";


const initialState={
    allRefCodes:[],
    newRefCode:{},
    updateRefCode:{}
};
const refCodesReducer=(state=initialState,action)=>{
    switch(action.type){
        case GET_ALL_REF_CODES:
            return{
                ...state,
                allRefCodes:action.payload
            }
        case CREATE_REF_CODE:
            return{
                ...state,
                newRefCode:action.payload
            }
        case UPDATE_RED_CODE:
            return{
                ...state,
                updateRefCode:action.payload
            }
        default:
            return state;
    }
}

export default refCodesReducer;