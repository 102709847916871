import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";

import notify from "../../hook/useNotification";
import {
  addProductToCart,
  getAllCartItems,
} from "../../redux/actions/cartAction";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const AddToCartHook = (prodId, item) => {
  const dispatch = useDispatch();
  const [indexColor, setIndexColor] = useState("");
  const [colorText, setColorText] = useState("");
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [sizeIndex, setSizeIndex] = useState("");
  const [sizeText, setSizeText] = useState("");

  const storeNameResponse = useSelector((state) => state.allStores.oneStore);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const webSiteData = useSelector(
    (state) => state.settingsReducers.webSiteData
  );
  const settings = useSelector((state) => state.settingsReducers.settings);

  const showReviews = settings ? settings.data?.showReviews : "";
  // console.log(showReviews)

  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }

  const colorClick = (index, color) => {
    setIndexColor(index);
    setColorText(color);
  };

  const sizeClick = (index, size) => {
    setSizeIndex(index);
    setSizeText(size);
  };

  const handleAddToCart = async () => {
    if (user.role === "admin" || user.role === "superAdmin") {
      notify(t("Admin is not allowed to add to the cart"), "warn");
      return;
    }
    if (item.countInStock === 0) {
      notify(t("Sorry, the product is currently out of stock"), "warn");
      return;
    }

    if (item?.colors.length >= 1 || item?.classifications.length >= 1) {
      setShow(true);
    } else {
      setLoading(true);
      await dispatch(
        addProductToCart({
          product: prodId,
          user: user._id,
          color: colorText,
          classification: sizeText,
          storeId:storeNameResponse?._id
        })
      );
      setLoading(false);
    }
  };

  const addProductColorSizeToCart = async () => {
    if (item.colors.length >= 1) {
      if (colorText === "") {
        notify(t("Choose the color of the product"), "warn");
        return;
      }
    } else {
      setColorText("");
    }

    if (item.classifications.length >= 1) {
      if (sizeText === "") {
        notify(t("Select the appropriate size"), "warn");
        return;
      }
    } else {
      setSizeText("");
    }
    setLoading(true);
    await dispatch(
      addProductToCart({
        product: prodId,
        user: user._id,
        color: colorText,
        classification: sizeText,
        storeId:storeNameResponse?._id
      })
    );
    setLoading(false);
  };

  const res = useSelector((state) => state.cartReducer.addToCart);
  // const res2 = useSelector((state) => state.cartReducer.userCartItems);

  useEffect(() => {
    if (loading === false) {
      if (res && res.success === true) {
        notify(t("The product has been added to the cart"), "success");
        setShow(false);
        dispatch(getAllCartItems(user._id));
      } else {
        if (res.response.data.msg === "multiVendor") {
          return notify(
            t("Products cannot be ordered by more than one Vendor"),
            "error"
          );
        } else {
          notify(t("Log in first"), "warn");
        }
      }
    }
  }, [loading]);
  // console.log(res2)

  // on click product navigate to product description
  const onClickProduct = (id) => {
    // console.log(id)
    // console.log('hello product')
    ReactGA.event({
      category: "product",
      action: "product_details_action",
      label: "product_label",
    });
    navigate(`/product/${id}`);
  };

  return [
    colorClick,
    indexColor,
    handleAddToCart,
    webSiteData,
    showReviews,
    onClickProduct,
    show,
    handleClose,
    sizeClick,
    sizeIndex,
    addProductColorSizeToCart,
  ];
};

export default AddToCartHook;
