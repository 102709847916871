import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/actions/usersActions";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getWebsiteData,
  getSettings,
} from "../../redux/actions/settingsAcions";
import {
  createNewSubscription,
  deleteSubsctiption,
  getAllSubscription,
} from "../../redux/actions/subscriptionAction";
import notify from "../useNotification";
import { useTranslation } from "react-i18next";

const AdminSubscriptionHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSelectStoreComponent, setShowSelectStoreComponent] =
    useState(false);
  const [showAddComponnet, setShowAddComponent] = useState(true);

  const [allSubscription, setAllSubscription] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);

  const [subscriptionId, setSubscriptionId] = useState("");

  const closeDeleteModal = () => setDeleteModal(false);

  const showDeleteModal = (id) => {
    setSubscriptionId(id);
    setDeleteModal(true);
  };

  //search params
  const [searchParams] = useSearchParams();
  const [PaymentId] = useState(searchParams.get("PaymentId"));
  const [Result] = useState(searchParams.get("Result"));
  const [ResponseCode] = useState(searchParams.get("ResponseCode"));
  const [amount] = useState(searchParams.get("amount"));
  const [period] = useState(searchParams.get("UserField5"));

  // console.log("PaymentId");
  // console.log(PaymentId);
  // console.log(Result);
  // console.log(ResponseCode);
  // console.log(amount);
  // console.log(period);
  // console.log('localStorage.getItem("isPay")');
  // console.log(localStorage.getItem("isPay"));
  useEffect(() => {
    if (
      localStorage.getItem("isPay") === "false" &&
      PaymentId !== "" &&
      ResponseCode === "000" &&
      Result === "Successful"
    ) {
      localStorage.removeItem("isPay");
      console.log("تم الدفع بنجاح ");
      dispatch(
        createNewSubscription({
          store: user.storeId,
          user: user._id,
          period: period,
          status: true,
        })
      );
      setTimeout(async () => {
        notify("تم الدفع بنجاح", "success");
        await dispatch(getAllSubscription(user?.storeId));
        navigate("/admin/subscriptions");
      }, 1000);
    }
  }, []);

  const { t } = useTranslation();

  //get store response
  const storeIdRes = useSelector(
    (state) => state.allStores.selectStoreBySuperAdmin
  );

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (user.role === "superAdmin") {
      setShowSelectStoreComponent(true);
      if (storeIdRes !== "") {
        dispatch(getAllSubscription(storeIdRes));
        setShowAddComponent(true);
      } else {
        setShowAddComponent(false);
        setAllSubscription([]);
      }
    } else dispatch(getAllSubscription(user?.storeId));
  }, [dispatch, storeIdRes, user.role, user?.storeId]);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const allSubscriptionResponse = useSelector(
    (state) => state.subscriptionReducer.allSubscriptions
  );

  useEffect(() => {
    if (allSubscriptionResponse) {
      setAllSubscription(allSubscriptionResponse);
    }
  }, [allSubscriptionResponse]);

  const clickHandler = (id) => {
    navigate(`/admin/editsliders/${id}`);
  };

  const deleteSubscription = async () => {
    console.log("deleteSubscription");
    await dispatch(deleteSubsctiption(subscriptionId));
    setDeleteModal(false);
    setTimeout(async () => {
      notify(t("Delete successfully"), "success");
      if (storeIdRes !== "") {
        await dispatch(getAllSubscription(storeIdRes));
      } else {
        await dispatch(getAllSubscription(user?.storeId));
      }
      setDeleteModal(false);
    }, 1000);
  };

  return [
    allSubscription,
    clickHandler,
    settingsRes,
    showSelectStoreComponent,
    showAddComponnet,
    storeIdRes,
    deleteModal,
    closeDeleteModal,
    showDeleteModal,
    deleteSubscription
  ];
};

export default AdminSubscriptionHook;
