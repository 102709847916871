import React from 'react'
import SideBar from '../../Components/Admin/SideBar';
import AdminAddStore from "../../Components/Admin/AdminAddStore";


function AdminAddStorePage() {
  return (
    <div className='d-flex justify-content-start' >
      <div>
      <SideBar />
      </div>
      <div className='p-4' style={{width:'100%'}} >
      <AdminAddStore />
      </div>
    </div>
  )
}

export default AdminAddStorePage;
