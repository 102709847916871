import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllStores,
  updateStore,
  updateStoreName,
} from "../../redux/actions/storesActions";
import notify from "../useNotification";
import { useSearchParams } from "react-router-dom";

function AdminAllStoresHook() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [stores, setStores] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [storeNameAr, setStoreNameAr] = useState("");

  // const[selectedRow,setSelectedRow]=useState();
  // console.log('stores');
  // console.log(stores);

  const storesRef = useRef(false);
  const editStoreRefId = useRef("");

  useEffect(() => {
    dispatch(getAllStores());
  }, []);

  const allStoresResponse = useSelector((state) => state.allStores.allStores);
  const settings = useSelector((state) => state.settingsReducers.settings);
  //let stores;

  useEffect(() => {
    if (allStoresResponse) setStores(allStoresResponse);
    // if (storesRef.current === false) {
    //     if (allStoresResponse) setStores(allStoresResponse);
    //     storesRef.current = true;
    //     return;
    // }
  }, [allStoresResponse]);

  useEffect(() => {
    async function get(search) {
      console.log("search");
      console.log(search);

      if (search == "all") {
        await dispatch(getAllStores());
      } else {
        let query = `status=${search}`;
        console.log("query");
        console.log(query);

        await dispatch(getAllStores(query));
      }
    }
    //console.log('nnnnnnnnnnnnn')
    let search =
      searchParams.get("status") == null ? "all" : searchParams.get("status");

    get(search);
  }, [searchParams]);

  async function upDateStoreStatus({ storeId, status, isDeleted }) {
    let upDateObj = {};
    upDateObj.status = status;
    if (isDeleted == true) upDateObj.isDeleted = true;

    var result = window.confirm("Want to edit or delete?");
    if (result) {
      //Logic to delete the item
      await dispatch(updateStore(storeId, upDateObj));
    }
    
  }

  async function handleUpdateStoreName() {
    console.log("editStoreRefId.current");
    console.log(editStoreRefId.current);
    await dispatch(
      updateStoreName({
        id: editStoreRefId.current,
        name: storeName,
        nameAr: storeNameAr,
      })
    );
    // setEditModal(false);
    // storesRef.current="";
    // setStoreName("");
    // setStoreNameAr("");
  }
  //newStoreName
  const { updatedStore, newStoreName } = useSelector(
    (state) => state.allStores
  );

  function handleShowModal(row) {
    console.log("row");
    console.log(row);
    setEditModal(true);
    setStoreName(row.name);
    setStoreNameAr(row.nameAr);
    editStoreRefId.current = row._id;
  }

  // console.log('editStoreRefId.current');
  // console.log(editStoreRefId.current);

  useEffect(() => {
    if (storesRef.current === false) {
      storesRef.current = true;
      return;
    }
    console.log("update");
    console.log(updatedStore);
    if (updatedStore?.success == true) {
      notify("تم التعديل بنجاح", "success");

      let newStoresArr = stores.map((store) =>
        store._id === updatedStore.store._id ? updatedStore.store : store
      );

      newStoresArr = newStoresArr.filter((el) => el.isDeleted == false);

      console.log("update");
      console.log(updatedStore);

      setStores(newStoresArr);
    } else {
      notify("فشل في عملية التعديل", "error");
    }
  }, [updatedStore]);

  useEffect(() => {
    if (editStoreRefId.current == "") return;

    if (newStoreName.success == true) {
      notify("تم التعديل بنجاح", "success");
      let newStoresArr = stores.map((store) =>
        store._id === editStoreRefId.current
          ? { ...store, name: storeName, nameAr: storeNameAr }
          : store
      );
      setStores(newStoresArr);
      editStoreRefId.current = "";
      setStoreName("");
      setStoreNameAr("");
      setEditModal(false);
    } else {
      notify("من فضلك ادخل اسم مميز للمتجر", "error");
    }
  }, [newStoreName]);

  return [
    stores,
    settings,
    upDateStoreStatus,
    editModal,
    setEditModal,
    handleShowModal,
    storeName,
    setStoreName,
    storeNameAr,
    setStoreNameAr,
    handleUpdateStoreName,
  ];
}

export default AdminAllStoresHook;
