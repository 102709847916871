import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { getOneAds, updateAds } from "../../redux/actions/adsAction";
import { useNavigate } from "react-router-dom";
import getStoreNameFromUrl from "../../Components/Utilities/getStoreNameFromUrl";

const storeName=getStoreNameFromUrl();

const AdminEditAdsHook = (id) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const run = async () => {
            await dispatch(getOneAds(id));
        };
        run();
    }, []);

    const item = useSelector((state) => state.adsReducers.oneAds);

    // const [image, setimage] = useState("");
    // const [selectedFile, setSelectedFile] = useState(null);

    const [title, settitle] = useState("");
    const [page, setpage] = useState("");
    const [key, setkey] = useState(0);
    const [loading, setLoading] = useState(true);
    const [check, setCheck] = useState(false);
    const [link, setlink] = useState("");
    const [images, setImages] = useState([]);
    const [link2, setLink2] = useState("");

    // show color picker
    const [showPicker, setShowPicker] = useState(false);
    const [color, setColor] = useState('');

    // show second color picker
    const [showSecondPicker, setShowSecondPicker] = useState(false);
    const [secondaryColor, setSecondaryColor] = useState('');


    const navigate = useNavigate()



    useEffect(() => {
        if (item) {
            settitle(item.title);
            setpage(item.page);
            setColor(item.color)
            setSecondaryColor(item.secondaryColor)
            setkey(item.key)
            setlink(item.link)
            setLink2(item.link2)
            if (item.images) setImages(item.images);


        }
    }, [item]);

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
    
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
    
        return new File([u8arr], filename, { type: mime });
      }
      //convert url to file
      const convertURLtoFile = async (url) => {
        const response = await fetch(url, { mode: "cors" });
        const data = await response.blob();
        const ext = url.split(".").pop();
        const filename = url.split("/").pop();
        const metadata = { type: `image/${ext}` };
        return new File([data], Math.random(), metadata);
      };


    const onChangeKey = (e) => {
        setkey(e.target.value);
    };
    const onChangeTitle = (e) => {
        settitle(e.target.value);
    };
    const onChangePage = (e) => {
        setpage(e.target.value);
    };
    // const onImageChange = (event) => {
    //     if (event.target.files && event.target.files[0])
    //         setimage(URL.createObjectURL(event.target.files[0]));
    //     setSelectedFile(event.target.files[0]);
    // };

    const onChangeLink = (e) => {
        setlink(e.target.value);
    };

    const onChangePicker = (e) => {
        setShowPicker(!showPicker);
    };
    const onChangeSecondPicker = (e) => {
        setShowSecondPicker(!showSecondPicker);
    };

    const handleChaneComplete = (color) => {
        setColor(color.hex);
        setShowPicker(!showPicker);
    };
    const handleChaneCompleteSecond = (color) => {
        setSecondaryColor(color.hex);
        setShowSecondPicker(!showSecondPicker);
    };

    const onChangeLink2 = (e) => {
        setLink2(e.target.value);
      };

    const handleSubmit = async (e) => {

        e.preventDefault();
        if (
            key == 0 ||
            page === "" ||
            images === []

        ) {
            notify("من فضلك اكمل البيانات", "warn");
            return;
        }

        let itemImages = [];
        //convert array of base 64 image to file
        Array.from(Array(Object.keys(images).length).keys()).map((item, index) => {
          if (images[index].length <= 1000) {
            // console.log("dd");
            convertURLtoFile(images[index]).then((val) => itemImages.push(val));
          } else {
            // console.log("ee");
            itemImages.push(dataURLtoFile(images[index], Math.random() + ".png"));
          }
        });

        const formData = new FormData();
        formData.append("title", title);
        formData.append("key", key);
        // formData.append("image", selectedFile);
        formData.append("color", color);
        formData.append("page", page);
        formData.append("secondaryColor", secondaryColor);
        formData.append("link", link);
        formData.append("link2", link2);
        itemImages.map((item) => formData.append("images", item));


        setCheck(true);
        setLoading(true);
        await dispatch(updateAds(id, formData));
        setLoading(false);
        setCheck(false);
    };

    const sliderRes = useSelector((state) => state.adsReducers.updateAds);
    useEffect(() => {
        if (loading === false) {
            // setTimeout(() => setLoading(true), 1500);
            if (sliderRes) {
                if (sliderRes.status === 200) {
                    notify("تم التعديل بنجاح", "success");
                    setTimeout(()=>{
                        navigate(`/admin/ads`)
                    },1000)
                    
                } else {
                    notify("فشل في عملية الاضافه", "error");
                }
            }
        }
    }, [loading]);

    return [
        title,
        key,
        showPicker,
        color,
        handleChaneComplete,
        handleSubmit,
        onChangeTitle,
        onChangeKey,
        onChangePicker,
        check,
        page,
        onChangePage,
        showSecondPicker,
        secondaryColor,
        handleChaneCompleteSecond,
        onChangeSecondPicker,
        link,
        onChangeLink,
        images,
        setImages,
        link2,
        onChangeLink2

    ];
};

export default AdminEditAdsHook;
