import React, { useState, useEffect } from "react";
import notify from "../useNotification";
import { addUser } from "../../redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getWebsiteData,
  getSettings,
} from "../../redux/actions/settingsAcions";
import { getVendorByusername } from "../../redux/actions/vendorActions";
import { getAllStores } from "../../redux/actions/storesActions";

const AdminAddUserHook = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [type, setType] = useState("");
  const [username, setUsername] = useState("");

  const [usernameExists, setUsernameExists] = useState();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [usernameLoading, setUsernameLoading] = useState(true);

  const [checkLoading, setcheckLoading] = useState(false);

  const [selectedStore, setSelectedStore] = useState("");

  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }
   //all store response
   const allStoresResponse = useSelector((state) => state.allStores.allStores);

  useEffect(()=>{
    if(allStoresResponse.length===0)  dispatch(getAllStores());
  },[]);

  const dispatch = useDispatch();

  const settingsRes = useSelector((state) => state.settingsReducers.settings);

 

  // console.log('allStoresResponse');
  // console.log(allStoresResponse);

  
  const webSiteData = useSelector(
    (state) => state.settingsReducers.webSiteData
  );

  const onChangeUsername = async (e) => {
    e.persist();
    setUsername(e.target.value);
    setcheckLoading(true);
    setUsernameLoading(true);
    await dispatch(getVendorByusername(e.target.value,selectedStore));
    setUsernameLoading(false);
    setcheckLoading(false);
  };

  //on change store
  const changeStore = (e) => {
    // console.log("e.target.value");
    // console.log(e.target.value);
    setSelectedStore(e.target.value);
  };

  const vendorByusername = useSelector(
    (state) => state.vendorReducer.vendorByUsername
  );

  useEffect(() => {
    if (usernameLoading === false) {
      if (vendorByusername && vendorByusername?._id) {
        setUsernameExists(true);
      } else {
        setUsernameExists(false);
      }
    }
  }, [usernameLoading]);

  //set First Name state
  const onChangeFirstname = (e) => {
    e.persist();
    setFirstname(e.target.value);
  };
  //set Last Name state
  const onChangeLastname = (e) => {
    e.persist();
    setLastname(e.target.value);
  };
  //set email state
  const onChangeEmail = (e) => {
    e.persist();
    setEmail(e.target.value);
  };
  //set mobile state
  const onChangeMobile = (e) => {
    e.persist();
    setMobile(e.target.value);
  };
  // set Password state
  const onChangePassword = (e) => {
    e.persist();
    setPassword(e.target.value);
  };
  // set Confirm Password state
  const onChangeConfirmPassword = (e) => {
    e.persist();
    setConfirmPassword(e.target.value);
  };
  // Set type state
  const onChangeType = (e) => {
    setType(e.target.value);
  };

  const onSubmit = async () => {
    if (firstname === "") {
      notify("من فضلك ادخل الاسم الاول", "error");
      return;
    }
    if (lastname === "") {
      notify("من فضلك ادخل الاسم الاخير", "error");
      return;
    }
    if (email === "") {
      notify("من فضلك ادخل الايميل ", "error");
      return;
    }
    if (mobile === "") {
      notify("من فضلك ادخل رقم الجوال ", "error");
      return;
    }
    if (mobile.length < 9) {
      notify("من فضلك ادخل رقم الجوال صحيح", "error");
      return;
    }
    if (password === "") {
      notify("من فضلك ادخل الباسورد", "error");
      return;
    }
    if (confirmPassword === "") {
      notify("من فضلك تاكيد الباسورد   ", "error");
      return;
    }
    if (password !== confirmPassword) {
      notify("من فضلك تاكد من تطابق كلمة السر", "error");
      return;
    }
    if (type === "" || type === "0") {
      notify("من فضلك تأكد من اختيار النوع", "error");
      return;
    }
    if (type === "vendor" && username === "") {
      notify("من فضلك تأكد من  إضافة اسم مميز للتاجر", "error");
      return;
    }
    if (user?.role === "superAdmin" && selectedStore === "") {
      notify("من فضلك تأكد من اختيار المتجر", "error");
      return;
    }
    if (usernameExists == true) {
      notify("من فضلك تأكد من اختيار اسم المستخدم", "error");
      return;
    } else {
      await dispatch(
        addUser({
          firstname: firstname,
          lastname: lastname,
          mobile: mobile,
          email: email,
          password: password,
          role: type,
          username: username,
          storeId: user?.role === "superAdmin" ? selectedStore : user?.storeId,
        })
      );
      setLoading(false);
    }
  };
  const response = useSelector((state) => state.usersReducers.addUser);
  useEffect(() => {
    if (loading === false) {
      if (response) {
        setFirstname("");
        setLastname("");
        setEmail("");
        setMobile("");
        setPassword("");
        setConfirmPassword("");
        setType("");
        notify("تم إضافة المستخدم بنجاح", "success");
      }
    }
  }, [loading]);

  return [
    firstname,
    lastname,
    email,
    mobile,
    password,
    confirmPassword,
    type,
    onChangeFirstname,
    onChangeLastname,
    onChangeEmail,
    onChangeMobile,
    onChangePassword,
    onChangeConfirmPassword,
    onChangeType,
    onSubmit,
    webSiteData,
    settingsRes,
    username,
    onChangeUsername,
    usernameExists,
    checkLoading,
    allStoresResponse,
    changeStore,
    user,
  ];
};

export default AdminAddUserHook;
