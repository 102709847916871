import React from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginHook from "../../hook/auth/LoginHook";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { FallingLines, ThreeDots } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import LoginSuperAdminHook from "../../hook/auth/LoginSuperAdminHook";

const LoginSuperAdmin = () => {
  const [
    val,
    password,
    onChangeVal,
    onChangePassword,
    loading,
    onSubmit,
    isPress,
  ] = LoginSuperAdminHook();
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const { t } = useTranslation();

  return (
    <Container style={{ minHeight: "670px" }}>
      <Row className="py-3 d-flex justify-content-center ">
        <Col sm="12" className="d-flex flex-column ">
          <h1 className="mx-auto py-5 "> {t("Super Admin")}</h1>
          <label className="mx-auto title-login"> {t("Sign In")}</label>
          <input
            value={val}
            onChange={onChangeVal}
            placeholder={t("Email or mobile number...")}
            type="text"
            className="user-input my-3 text-center mx-auto"
          />
          <input
            value={password}
            onChange={onChangePassword}
            placeholder={t("password...")}
            type="password"
            className="user-input text-center mx-auto"
          />
          <button
            disabled={isPress === true ? true : false}
            onClick={onSubmit}
            className="btn-login mx-auto mt-4"
            style={
              isPress
                ? { backgroundColor: "grey" }
                : { backgroundColor: "#000" }
            }
          >
            {t("Sign In")}
          </button>
          <label className="mx-auto my-4">
            {t("Create a new store") + "؟ "}
            <Link to="/registerstore" style={{ textDecoration: "none" }}>
              <span style={{ cursor: "pointer" }} className="text-danger">
                {t("press here")}
              </span>
            </Link>
          </label>
          {isPress === true ? (
            loading === true ? (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color={settingsRes?.data?.primaryColor}
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : null
          ) : null}
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default LoginSuperAdmin;
