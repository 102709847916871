import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllAds, deleteAds } from "../../redux/actions/adsAction";
import notify from "../useNotification";
import { useTranslation } from "react-i18next";

const AdminViewAdsHook = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [adsId, setAdsId] = useState("");
  const [showSelectStoreComponent, setShowSelectStoreComponent] =
    useState(false);
  const [showAddComponnet, setShowAddComponent] = useState(true);
  const [allAds, setAllAds] = useState([]);

  const { t } = useTranslation();

  const closeDeleteModal = () => setDeleteModal(false);

  const showDeleteModal = (id) => {
    setAdsId(id);
    setDeleteModal(true);
  };
  const user = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //get store response
  const storeIdRes = useSelector(
    (state) => state.allStores.selectStoreBySuperAdmin
  );
  useEffect(() => {
    if (user.role === "superAdmin") {
      setShowSelectStoreComponent(true);
      if (storeIdRes !== "") {
        dispatch(getAllAds(storeIdRes));
        setShowAddComponent(true);
      } else {
        setShowAddComponent(false);
        setAllAds([]);
      }
    } else dispatch(getAllAds(user?.storeId));
  }, [dispatch, storeIdRes, user.role, user?.storeId]);

  const allAdsResponse = useSelector((state) => state.adsReducers.allAds);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  useEffect(() => {
    if (allAdsResponse) {
      setAllAds(allAdsResponse);
    }
  }, [allAdsResponse]);

  const deleteAdss = async () => {
    console.log("deleteAds");
    await dispatch(deleteAds(adsId));
    setDeleteModal(false);
    setTimeout(async () => {
      notify(t("Delete successfully"), "success");
      if (storeIdRes !== "") {
        await dispatch(getAllAds(storeIdRes));
      } else {
        await dispatch(getAllAds(user?.storeId));
      }
      setDeleteModal(false);
    }, 1000);
  };

  const clickHandler = (id) => {
    navigate(`/admin/editAds/${id}`);
  };

  return [
    allAds,
    clickHandler,
    settingsRes,
    deleteModal,
    closeDeleteModal,
    showDeleteModal,
    deleteAdss,
    showSelectStoreComponent,
    showAddComponnet,
    user,
    storeIdRes,
  ];
};

export default AdminViewAdsHook;
