import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/actions/usersActions";
import { useNavigate } from "react-router-dom";
import {
  getWebsiteData,
  getSettings,
} from "../../redux/actions/settingsAcions";
import { getAllSlider } from "../../redux/actions/sliderActions";

const AdmiViewSliderHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSelectStoreComponent, setShowSelectStoreComponent] =
    useState(false);
  const [showAddComponnet, setShowAddComponent] = useState(true);

  const [allSlider, setAllSlider] = useState([]);

  //get store response
  const storeIdRes = useSelector(
    (state) => state.allStores.selectStoreBySuperAdmin
  );

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (user.role === "superAdmin") {
      setShowSelectStoreComponent(true);
      if (storeIdRes !== "") {
        dispatch(getAllSlider(storeIdRes));
        setShowAddComponent(true);
      } else {
        setShowAddComponent(false);
        setAllSlider([]);
      }
    } else dispatch(getAllSlider(user?.storeId));
  }, [dispatch, storeIdRes, user.role, user?.storeId]);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const allSliders = useSelector((state) => state.sliderReducer.allSlider);

  useEffect(() => {
    if (allSliders) {
      setAllSlider(allSliders);
    }
  }, [allSliders]);

  const clickHandler = (id) => {
    navigate(`/admin/editsliders/${id}`);
  };

  return [
    allSlider,
    clickHandler,
    settingsRes,
    showSelectStoreComponent,
    showAddComponnet,
    storeIdRes,
  ];
};

export default AdmiViewSliderHook;
