import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ViewAllUsersHook from "../../hook/admin/ViewAllUsersHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import AdminEditUserHook from "../../hook/admin/AdminEditUserHook";
import { updateUser } from "../../redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { ToastContainer } from "react-toastify";
import add from "../../Images/add.png";
import { useNavigate } from "react-router-dom";
import AdmiViewSliderHook from "../../hook/admin/AdmiViewSliderHook";
import { getAllSlider, updateSlider } from "../../redux/actions/sliderActions";
import AddButton from "../Utilities/AddButton";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import getStoreNameFromUrl from "../Utilities/getStoreNameFromUrl";
import SuperAdminSelectStoreComponent from "../Utilities/SuperAdminSelectStoreComponent";

const storeName = getStoreNameFromUrl();

const AdminViewslider = () => {
  const { t } = useTranslation();

  const [
    allSlider,
    clickHandler,
    settingsRes,
    showSelectStoreComponent,
    showAddComponnet,
    storeIdRes,
  ] = AdmiViewSliderHook();

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));

  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateSlider(id, {
        status: status,
      })
    );
    setLoading(false);
  };

  const res = useSelector((state) => state.sliderReducer.updateSlider);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify(t("Modified successfully"), "success");
        setTimeout(async () => {
          if (storeIdRes !== "") {
            await dispatch(getAllSlider(storeIdRes));
          } else {
            await dispatch(getAllSlider(user?.storeId));
          }
        }, 1500);
      } else {
        notify(t("Failed in the editing process"), "error");
      }
    }
  }, [loading]);

  let columns = [
    {
      name: t("image"),
      cell: (row) => (
        <>
          {row.images[0] && (
            <img
              alt="sliderImage"
              src={row.images[0]}
              width="50px"
              height="50px"
              style={{ padding: "3px", objectFit: "contain" }}
            />
          )}
          {row.images[1] && (
            <img
              alt="sliderImage"
              src={row.images[1]}
              width="50px"
              height="50px"
              style={{ padding: "3px", objectFit: "contain" }}
            />
          )}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("address"),
      selector: "title",
      sortable: true,
    },

    {
      name: t("details"),
      selector: "description",
      sortable: true,
    },
    {
      name: t("background color"),
      cell: (row) => (
        <div
          className="color ms-2 border  mt-1"
          style={{ backgroundColor: row.color }}
        ></div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("Modify"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("the status"),
      cell: (row) =>
        row.status === true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
            {t("active")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: allSlider,
  };

  return (
    <div>
      <Helmet>
        <title>
          {` ${t("image bar")} -${
            Cookies.get("i18next") == "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>

      <div className="d-flex justify-content-between">
        <div className="admin-content-text"> {t("image bar mangment")}</div>

        {showAddComponnet && (
          <AddButton title={t("add image bar")} navigateTo={`/admin/sliders`} />
        )}
      </div>

      {showSelectStoreComponent === true && <SuperAdminSelectStoreComponent />}

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};
export default AdminViewslider;
