import React from "react";
import PayMethodsAdmin from "../../Components/Checkout/PayMethodsAdmin";
import SideBar from "../../Components/Admin/SideBar";

const AdminPayMethodsPage = ()=>{
return (

    <div className="d-flex justify-content-start">
    <div>
      <SideBar />
    </div>
    <div className="p-4" style={{ width: '100%' }}>
    <PayMethodsAdmin/>
    </div>
  </div>



)
}

export default AdminPayMethodsPage

// <Container style={{ minHeight: "670px" }}>
// <PayMethodsAdmin/>
// </Container>