import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import {
  addShippingMehod,
  getShippingMethods,
} from "../../redux/actions/shippingMethodAction";
import { getSettings } from "../../redux/actions/settingsAcions";

const AddShippingHook = () => {
  const [paymentMethodName_Ar, setPaymentMethodName_Ar] = useState("");
  const [paymentMethodName_En, setPaymentMethodName_En] = useState("");

  const [wieghtNumber, setwieghtNumber] = useState("");
  const [wieghtCost, setwieghtCost] = useState("");
  const [wieghtExtraCost, setwieghtExtraCost] = useState("");

  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [showSelectStoreComponent, setShowSelectStoreComponent] =
    useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  //   const [paymentMethodStatus, setpaymentMethodStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [tablekeysValues, settablekeysValues] = useState([]);

  const dispatch = useDispatch();

  const onChangePayMethodName_Ar = (e) => {
    e.persist();
    setPaymentMethodName_Ar(e.target.value);
  };
  const onChangePayMethodName_En = (e) => {
    e.persist();
    setPaymentMethodName_En(e.target.value);
  };

  const onChangewieghtNumber = (e) => {
    e.persist();
    setwieghtNumber(e.target.value);
  };
  const onChangewieghtCost = (e) => {
    e.persist();
    setwieghtCost(e.target.value);
  };
  const onChangewieghtExtraCost = (e) => {
    e.persist();
    setwieghtExtraCost(e.target.value);
  };

  const onChangeKey = (e) => {
    e.persist();
    setKey(e.target.value);
  };
  const onChangeValue = (e) => {
    e.persist();
    setValue(e.target.value);
  };

  const clickHandler = () => {
    settablekeysValues([
      ...tablekeysValues,
      { key: key, value: value, count: tablekeysValues.length + 1 },
    ]);

    setKey("");
    setValue("");
  };

  const deleteElement = (count) => {
    settablekeysValues(tablekeysValues.filter((el) => el.count !== count));
  };

  const onSubmit = async () => {
    if (
      paymentMethodName_Ar === "" ||
      paymentMethodName_En === "" ||
      wieghtNumber === "" ||
      wieghtCost === "" ||
      wieghtExtraCost === ""
    ) {
      notify("من فضلك اكمل البيانات", "error");
      return;
    }
    setLoading(true);
    await dispatch(
      addShippingMehod({
        nameAr: paymentMethodName_Ar,
        nameEn: paymentMethodName_En,

        wieghtNumber: wieghtNumber,
        wieghtCost: wieghtCost,
        wieghtExtraCost: wieghtExtraCost,

        settingsData: tablekeysValues,
        storeId: user.role=="superAdmin"? storeIdRes : user?.storeId,
      })
    );
    setLoading(false);
  };

  const response = useSelector(
    (state) => state.shippingMethodReducer.addshipping
  );

  useEffect(() => {
    if (loading === false) {
      if (response) {
        setPaymentMethodName_Ar("");
        setPaymentMethodName_En("");

        setwieghtNumber("");
        setwieghtCost("");
        setwieghtExtraCost("");

        notify("تم الحفظ بنجاح", "success");
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      } else {
        notify("فشل في عملية الاضافه", "error");
      }
    }
  }, [loading]);

  const allPaymentMethods = useSelector(
    (state) => state.shippingMethodReducer.shipping
  );
  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const storeIdRes = useSelector(
    (state) => state.allStores.selectStoreBySuperAdmin
  );
  useEffect(() => {
    const get = async () => {
      await dispatch(getShippingMethods(user.storeId));
    };

    if(user.role=="superAdmin"){
        setShowSelectStoreComponent(true);
    }
    else{
      setShowSelectStoreComponent(false);
      get();
    }
    
  }, []);

  useEffect(()=>{
    if(storeIdRes) dispatch(getShippingMethods(storeIdRes));
  },[storeIdRes]);

  return [
    paymentMethodName_Ar,
    paymentMethodName_En,
    onChangePayMethodName_Ar,
    onChangePayMethodName_En,
    allPaymentMethods,
    onSubmit,
    key,
    onChangeKey,
    value,
    onChangeValue,
    tablekeysValues,
    clickHandler,
    show,
    handleShow,
    deleteElement,
    settingsRes,
    wieghtNumber,
    wieghtCost,
    wieghtExtraCost,
    onChangewieghtNumber,
    onChangewieghtCost,
    onChangewieghtExtraCost,
    showSelectStoreComponent,
    storeIdRes
  ];
};

export default AddShippingHook;
